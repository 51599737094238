
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Order } from "@/orders/entities/order";
import { Center, Commodity, Day, Entity, OrderStatus, ServicePoint } from "@/orders";
import PendingOrders from "@/orders/components/Pending/PendingOrders.vue";
import Icon from "@/orders/components/Icon/index.vue";
import NavigationWeek from "@/orders/components/NavigationWeek/index.vue";
import OptionsCalendar from "@/orders/components/OptionsCalendar/index.vue";
import Toast from "@/orders/components/Toast/index.vue";
import CardDetail from "@/orders/components/CardDetail/index.vue";
import FilterOrders from "@/orders/components/FiltersOrders/index.vue";
import WeekConfig from "@/orders/components/WeekConfig/index.vue";
import PAside from "@/orders/components/Aside/PAside.vue";
import ModalCreate from "@/orders/components/ModalCreate/ModalCreate.vue";
import VueMethods from "@/vue-methods";
import VueOrders from "@/orders/vue-orders";
import Tripulation from "@/orders/components/Tripulation/index.vue";

@Component({
  components: {
    Icon,
    NavigationWeek,
    OptionsCalendar,
    Toast,
    CardDetail,
    FilterOrders,
    WeekConfig,
    PendingOrders,
    PAside,
    Tripulation,
    ModalCreate,
  },
  mixins: [VueMethods, VueOrders],
})
export class HomeViewPage extends Vue {
  @Getter("getOrders") getOrders!: Order[];
  @Getter("getServicePointsOrders") getServicePoints!: ServicePoint[];
  @Getter("getStatuses") getStatuses!: OrderStatus[];
  @Getter("getCommodities") getCommodities!: Commodity[];
  plus = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  eyesOff = require("@/orders/assets/icons/icon-eye-off.svg");
  eyes = require("@/orders/assets/icons/icon-eye.svg");
  filter = require("@/orders/assets/icons/icon-filtrar.svg");
  expand = require("@/orders/assets/icons/icon-maximize-2.svg");
  notexpand = require("@/orders/assets/icons/icon-minimize-2.svg");
  prosegur = require("@/orders/assets/icons/icon-prosegur.svg");
  setting = require("@/orders/assets/icons/icon-settings.svg");
  right = require("@/orders/assets/icons/icon-chevron-right.svg");
  down = require("@/orders/assets/icons/icon-chevron-down.svg");
  notification = require("@/orders/assets/icons/diferencia-icon.svg");

  filterComoddities: string[] = [];
  filterStatutes: string[] = [];
  orderSelected: Order | null = null;
  openModal = false;
  openModalCreate = false;
  openModalFilter = false;
  openModalWeek = false;
  openFilters = false;
  openModalPending = false;
  openModalTripulation = false;
  toggleFilterService = false;
  toggleFilterState = false;
  showNotOrders = false;
  configView: {
    startDay: string;
    sortBy: string;
  } = { startDay: "sunday", sortBy: "code" };
  expandEntities: string[] = [];
  date = new Date();
  filtersOrders: {
    servicePoints: ServicePoint[];
    centers: Center[];
    entities: Entity[];
  } = { servicePoints: [], centers: [], entities: [] };
  schedulerBodyWidth = 0;

  isExpandedRow(id: string): boolean {
    setTimeout(() => {
      const schedulerLi: any = this.$refs[id];
      const entityLi: any = this.$refs["entity-" + id];
      if (schedulerLi && entityLi) {
        entityLi[0].style.height = schedulerLi[0].offsetHeight + "px";
      } else {
        entityLi[0].style.height = "";
      }
    }, 1);
    return this.expandEntities.includes(id);
  }

  isExpandedAll(): boolean {
    return this.expandEntities.length == this.orderedPoints.length;
  }

  expandAll(): void {
    if (this.isExpandedAll()) {
      this.expandEntities = [];
    } else {
      this.orderedPoints.forEach((value) => {
        if (!this.isExpandedRow(value.id)) {
          this.expandEntities.push(value.id);
        }
      });
    }
  }

  get ordersPending(): Order[] {
    return this.getOrders.filter((item) => item.orderStatus.code === "PEN");
  }
  get servicePointsFiltered() {
    if (this.filtersOrders.servicePoints.length) {
      return this.getServicePoints.filter((value) => this.filtersOrders.servicePoints.includes(value));
    } else if (this.filtersOrders.centers.length) {
      return this.getServicePoints.filter((value) =>
        this.filtersOrders.centers.map((value1) => value1.code).includes(value.center.code)
      );
    } else if (this.filtersOrders.entities.length) {
      return this.getServicePoints.filter((value) =>
        this.filtersOrders.entities.map((value1) => value1.code).includes(value.entity.code)
      );
    }
    return this.getServicePoints;
  }

  get orderedPoints(): ServicePoint[] {
    let returnValue = [];
    if (this.showNotOrders) {
      returnValue = this.servicePointsFiltered.filter((point: ServicePoint) => {
        for (let i = 0; i < this.getOrders.length; i++) {
          if (point.id === this.getOrders[i].servicePoint.id) {
            return point;
          }
        }
      });
    } else {
      returnValue = this.servicePointsFiltered;
    }
    return returnValue.sort((a, b) => {
      try {
        return this.sortAscendingIgnoreCase(a, b, this.configView.sortBy);
      } catch {
        return this.sortAscending(a, b, this.configView.sortBy);
      }
    });
  }

  updateBodyWidth() {
    const elementBody = this.$refs.schedulerBody;
    if (elementBody instanceof HTMLElement) {
      this.schedulerBodyWidth = elementBody.offsetWidth;
    }
  }

  mounted(): void {
    if (localStorage.configWeek) {
      this.configView = JSON.parse(localStorage.configWeek);
    }
    setTimeout(() => {
      this.getCommodities.forEach((value) => this.filterComoddities.push(value.code));
      this.getStatuses.forEach((value) => this.filterStatutes.push(value.code));
    }, 1000);
    this.updateBodyWidth();
    window.addEventListener("resize", this.updateBodyWidth);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.updateBodyWidth);
  }

  extractorTitle(str: string) {
    const letter = str.match(/\b[A-Z]/g);
    if (letter) {
      return letter.map((letter: string) => letter[0]).join("");
    } else {
      return "";
    }
  }

  changeExpand(id: string) {
    if (this.isExpandedRow(id)) {
      this.expandEntities = this.expandEntities.filter((value) => value != id);
    } else {
      this.expandEntities.push(id);
    }
  }

  getOrderFromDay(day: Day, servicepoint: ServicePoint): Order[] {
    return this.getOrders
      .filter((order) => order.commodity.name !== "SUPPLY" && order.serviceDate !== null)
      .filter((order) => {
        const orderDate = this.dateFromString(order.serviceDate);
        if (
          servicepoint.id === order.servicePoint.id &&
          (this.filterComoddities.length == 0 || this.filterComoddities.includes(order.commodity.code)) &&
          (this.filterStatutes.length == 0 || this.filterStatutes.includes(order.orderStatus.code))
        ) {
          return orderDate.getDate() === day.number && orderDate.getMonth() === this.date.getMonth();
        }
      });
  }

  dateFromString(dateString: string): Date {
    const index = dateString.indexOf("[");
    if (index >= 0) {
      return new Date(dateString.slice(0, index));
    }
    return new Date(dateString);
  }

  changeOrderSelected(order: Order) {
    this.orderSelected = order;
    this.openModal = true;
  }

  isToday(date: Date) {
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  shortDay(day: string) {
    return day.substring(0, 3).toUpperCase();
  }

  get weekDays(): Day[] {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let startDayIndex = daysOfWeek.indexOf(this.configView.startDay.toLowerCase());
    if (startDayIndex === -1) {
      startDayIndex = daysOfWeek.indexOf(new Date().toLocaleDateString("en-EN", { weekday: "long" }).toLowerCase());
    }
    const currentDate = this.date;
    const currentDayOfWeek = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    let dayToStart = startDayIndex - currentDayOfWeek;
    if (dayToStart > 0) dayToStart -= 7;
    firstDayOfWeek.setDate(currentDate.getDate() + dayToStart);
    const days = [];
    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(firstDayOfWeek);
      nextDay.setDate(firstDayOfWeek.getDate() + i);
      days.push({
        name: nextDay.toLocaleDateString("es-ES", { weekday: "long" }),
        number: nextDay.getDate(),
        month: nextDay.getMonth(),
        active: this.isToday(nextDay),
        dayPermissionCreate: nextDay.getDate() >= new Date().getDate(),
      });
    }
    return days;
  }

  showTripulation(order: Order): void {
    this.orderSelected = order;
    this.openModalTripulation = true;
  }
  showDetail(order: Order): void {
    this.changeOrderSelected(order);
  }

  onCreatedOrdersMessages(createdOrdersMessages: string[]): void {
    createdOrdersMessages.forEach((createdOrdersMessage) => this.makeToast("success", createdOrdersMessage));
  }

  makeToast(variant: string, message: string): void {
    this.$bvToast.toast(message, {
      autoHideDelay: 4000,
      variant,
      toaster: "b-toaster-bottom-center",
      noCloseButton: true,
    });
  }
}
export default HomeViewPage;
