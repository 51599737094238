
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Order } from "@/orders/entities/order";
import { Center, Commodity, Day, Entity, OrderStatus, ServicePoint } from "@/orders";
import Icon from "@/orders/components/Icon/index.vue";
import NavigationWeek from "@/orders/components/NavigationWeek/index.vue";
import OptionsCalendar from "@/orders/components/OptionsCalendar/index.vue";
import Toast from "@/orders/components/Toast/index.vue";
import CardDetail from "@/orders/components/CardDetail/index.vue";
import FilterOrders from "@/orders/components/FiltersOrders/index.vue";
import WeekConfig from "@/orders/components/WeekConfig/index.vue";
import VueMethods from "@/vue-methods";
import PendingOrders from "@/orders/components/Pending/PendingOrders.vue";
import PAside from "@/orders/components/Aside/PAside.vue";
import VueOrders from "@/orders/vue-orders";
import Tripulation from "@/orders/components/Tripulation/index.vue";
import ModalCreate from "@/orders/components/ModalCreate/ModalCreate.vue";

@Component({
  components: {
    Icon,
    NavigationWeek,
    OptionsCalendar,
    Toast,
    CardDetail,
    FilterOrders,
    WeekConfig,
    PendingOrders,
    PAside,
    Tripulation,
    ModalCreate,
  },
  mixins: [VueMethods, VueOrders],
})
export class MonthViewPage extends Vue {
  @Getter("getOrders") getOrders!: Order[];
  @Getter("getServicePointsOrders") getServicePoints!: ServicePoint[];
  @Getter("getStatuses") getStatuses!: OrderStatus[];
  @Getter("getCommodities") getCommodities!: Commodity[];
  plus = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  filter = require("@/orders/assets/icons/icon-filtrar.svg");
  setting = require("@/orders/assets/icons/icon-settings.svg");
  right = require("@/orders/assets/icons/icon-chevron-right.svg");
  notification = require("@/orders/assets/icons/diferencia-icon.svg");

  filterComoddities: string[] = [];
  filterStatutes: string[] = [];
  orderSelected: Order | null = null;
  openModal = false;
  openModalCreate = false;
  openModalFilter = false;
  openModalWeek = false;
  openFilters = false;
  openModalPending = false;
  openModalTripulation = false;
  toggleFilterService = false;
  toggleFilterState = false;
  configView: {
    startDay: string;
    sortBy: string;
  } = { startDay: "sunday", sortBy: "code" };
  date = new Date();
  filtersOrders: {
    servicePoints: ServicePoint[];
    centers: Center[];
    entities: Entity[];
  } = { servicePoints: [], centers: [], entities: [] };

  get ordersPending(): Order[] {
    return this.getOrders.filter((item) => item.orderStatus.code === "PEN");
  }

  get servicePointsFiltered() {
    if (this.filtersOrders.servicePoints.length) {
      return this.getServicePoints.filter((value) => this.filtersOrders.servicePoints.includes(value));
    } else if (this.filtersOrders.centers.length) {
      return this.getServicePoints.filter((value) =>
        this.filtersOrders.centers.map((value1) => value1.code).includes(value.center.code)
      );
    } else if (this.filtersOrders.entities.length) {
      return this.getServicePoints.filter((value) =>
        this.filtersOrders.entities.map((value1) => value1.code).includes(value.entity.code)
      );
    }
    return this.getServicePoints;
  }

  get orderedPoints(): ServicePoint[] {
    return this.servicePointsFiltered.sort((a, b) => {
      try {
        return this.sortAscendingIgnoreCase(a, b, this.configView.sortBy);
      } catch {
        return this.sortAscending(a, b, this.configView.sortBy);
      }
    });
  }

  mounted(): void {
    if (localStorage.configWeek) {
      this.configView = JSON.parse(localStorage.configWeek);
    }
    setTimeout(() => {
      this.getCommodities.forEach((value) => this.filterComoddities.push(value.code));
      this.getStatuses.forEach((value) => this.filterStatutes.push(value.code));
    }, 1000);
  }

  classDay(day: Day) {
    if (day.month !== this.date.getMonth()) {
      return "disabled other";
    }
  }

  getOrderFromDay(day: Day): Order[] {
    return this.getOrders
      .filter((order) => order.commodity.name !== "SUPPLY" && order.serviceDate !== null)
      .filter((order) => {
        const orderDate = this.dateFromString(order.serviceDate);
        if (
          (this.filterComoddities.length == 0 || this.filterComoddities.includes(order.commodity.code)) &&
          (this.filterStatutes.length == 0 || this.filterStatutes.includes(order.orderStatus.code))
        ) {
          return orderDate.getDate() === day.number && orderDate.getMonth() === this.date.getMonth();
        }
      });
  }

  dateFromString(dateString: string): Date {
    const index = dateString.indexOf("[");
    if (index >= 0) return new Date(dateString.slice(0, index));
    return new Date(dateString);
  }

  changeOrderSelected(order: Order) {
    this.orderSelected = order;
    this.openModal = true;
  }

  isToday(date: Date) {
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  shortDay(day: string) {
    return day.substring(0, 3).toUpperCase();
  }

  get monthDays() {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let startDayIndex = daysOfWeek.indexOf(this.configView.startDay.toLowerCase());
    if (startDayIndex === -1) {
      startDayIndex = daysOfWeek.indexOf(new Date().toLocaleDateString("en-EN", { weekday: "long" }).toLowerCase());
    }
    const currentDate = this.date;
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentDate.getMonth(), 1);
    const firstDayOfMonthIndex = firstDayOfMonth.getDay();
    const startDayOffset = (7 + (firstDayOfMonthIndex - startDayIndex)) % 7;

    const days = [];

    // Generate days for the previous month if needed
    const prevMonth = new Date(currentYear, currentDate.getMonth(), 1 - startDayOffset);
    for (let i = 0; i < startDayOffset; i++) {
      days.push({
        name: prevMonth.toLocaleDateString("es-ES", { weekday: "long" }),
        number: prevMonth.getDate(),
        month: prevMonth.getMonth(),
        active: false,
      });
      prevMonth.setDate(prevMonth.getDate() + 1);
    }

    // Generate days for the current month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        name: firstDayOfMonth.toLocaleDateString("es-ES", { weekday: "long" }),
        number: firstDayOfMonth.getDate(),
        month: firstDayOfMonth.getMonth(),
        active: this.isToday(firstDayOfMonth),
      });
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    // Generate days for the next month
    const nextMonth = new Date(currentYear, currentDate.getMonth() + 1, 1);
    while (days.length % 7 !== 0) {
      days.push({
        name: nextMonth.toLocaleDateString("es-ES", { weekday: "long" }),
        number: nextMonth.getDate(),
        month: nextMonth.getMonth(),
        active: false,
      });
      nextMonth.setDate(nextMonth.getDate() + 1);
    }

    return days;
  }

  showTripulation(order: Order): void {
    this.orderSelected = order;
    this.openModalTripulation = true;
  }
}
export default MonthViewPage;
