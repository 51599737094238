
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/orders";

@Component
export class PendingOrders extends Vue {
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) listOrdersPending!: Order[];
  image = require("@/orders/assets/icons/icon-pedidos-entrega-de-cambio.svg");
  imageDown = require("@/orders/assets/icons/icon-chevron-down.svg");
  imagePlus = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  showPendingPrograming = true;

  closeModal() {
    this.$emit("closeModal", !this.active);
  }
  closeOrderPending() {
    console.log("closeOrderPending");
    this.showPendingPrograming = false;
  }
  openOrderPending() {
    console.log("openOrderPending");
    this.showPendingPrograming = true;
  }
}
export default PendingOrders;
