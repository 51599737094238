
import Icon from "@/orders/components/Icon/index.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Center } from "@/orders";

@Component({
  components: {
    Icon,
  },
})
export class Centers extends Vue {
  @Getter("getCenters") getCenters!: Center[];

  eyes = require("@/orders/assets/icons/icon-eye.svg");
  itemsSelected: Center[] = [];
  searchTerm = "";
  isSelectAll = true;

  selectAll(val: boolean): void {
    this.isSelectAll = !this.isSelectAll;
    const selectedTemporal: Center[] = [];
    if (this.isSelectAll) {
      this.filterItems.forEach((item) => {
        selectedTemporal.push(item);
      });
    }
    this.itemsSelected = selectedTemporal;
  }

  @Watch("itemsSelected", { immediate: true, deep: true })
  onChangeItemsSelected(): void {
    this.isSelectAll = this.itemsSelected.length == this.filterItems.length;
    this.$emit("itemsSelected", this.itemsSelected);
  }

  get filterItems(): Center[] {
    const filterItems = this.getCenters.filter((item: Center) => {
      const searchTermLowerCase = this.searchTerm.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchTermLowerCase) ||
        item.code.toLowerCase().includes(searchTermLowerCase) ||
        item.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.searchTerm.toLowerCase()) >= 0 ||
        item.code
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.searchTerm.toLowerCase()) >= 0
      );
    });
    if (this.isSelectAll) {
      this.itemsSelected = filterItems;
    } else {
      const temporalSelected = this.itemsSelected.filter((value) => filterItems.includes(value));
      this.itemsSelected = temporalSelected;
    }
    return filterItems;
  }
}

export default Centers;
