
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export class Toast extends Vue {
  @Prop({ required: false }) width!: number;

  toggle = false;
  isTooltipOverflowing = false;
  tooltipWidth = 0;

  handleMouseOver() {
    this.toggle = true;
    this.$nextTick(() => {
      const tooltip = this.$el.querySelector(".toast__content");
      const containerRect = this.$el.getBoundingClientRect();
      if (tooltip instanceof HTMLElement && containerRect) {
        this.tooltipWidth = tooltip.offsetWidth;
        this.isTooltipOverflowing = this.width - containerRect.left < this.tooltipWidth - 20;
      }
    });
  }
}
export default Toast;
