import { FieldPTable, FieldPTableType, FormatterFieldType } from "@/common/components";
import { EEarlyValueNotificationType, ETransactionOrigin, ETransactionType, IStaticData } from "@/entities";
import { i18n } from "@/i18n";
import DateTimeMixin from "@/mixins/datetime";
import { RawI18n } from "@/services";
import { LocalDate, LocalDateTime } from "@js-joda/core";
import { Context, IActions } from "@/store";
import {
  CreateOrderAssistanceService,
  CreateOrderDeliveryService,
  CreateOrderPickupService,
  CreateOrderSupplyService,
  ServicePoint,
} from "@/orders";

export const actions: IActions = {
  // Root
  async initialize(context: Context): Promise<void> {
    if (context.getters.getIsSignedIn) {
      await Promise.all([context.dispatch("updateLoggedUser"), context.dispatch("fetchLoggedUser")]);
    }
  },

  setLocale(context: Context, locale: string) {
    context.commit("SET_LOCALE", locale);
  },

  setUrl(context: Context, url: string) {
    context.commit("SET_URL", url);
  },

  // User
  async fetchLoggedUser(context: Context): Promise<void> {
    const data = await this.$services.user.fetchMe();
    context.commit("SET_LOGGED_USER", data);
  },
  async updateLoggedUser(): Promise<void> {
    await this.$services.user.updateMe();
  },

  // Auth
  setSignedIn(context: Context, isSignedIn: boolean) {
    context.commit("SET_IS_SIGNED_IN", isSignedIn);
  },

  // I118n
  async fetchI18n(): Promise<RawI18n> {
    return this.$services.i18n.fetchI18n();
  },

  // Country
  async fetchCountries(context: Context): Promise<void> {
    const data = await this.$services.country.fetchCountries();
    context.commit("SET_COUNTRIES_PAGE", data);
  },

  // Static Data
  async fetchStaticData(context: Context): Promise<void> {
    const dateTimeFormatter = new DateTimeMixin();
    const localDateTimeFormatter: FormatterFieldType = (ldt: LocalDateTime): string | undefined =>
      dateTimeFormatter.formatDateTime(dateTimeFormatter.toDate(ldt)) || "";
    const localDateFormatter: FormatterFieldType = (ldt: LocalDateTime): string | undefined =>
      dateTimeFormatter.formatDate(dateTimeFormatter.toDate(ldt)) || "";
    const transactionTypeFormatter: FormatterFieldType = (e: ETransactionType): string | undefined => {
      return e ? "" + i18n.t("transactions.transactionType." + e) : "";
    };
    const transactionOriginFormater: FormatterFieldType = (e: ETransactionOrigin): string | undefined => {
      return e ? "" + i18n.t("transactions.transactionOrigin." + e) : "";
    };
    const decimalFormatter: FormatterFieldType = (num: string | number): string | undefined => {
      const numValue = num ? Number(num) : Number(0);
      return i18n.n(numValue, { minimumFractionDigits: 2, useGrouping: true }) + "";
    };
    const earlyValueAccreditationTypeFormatter: FormatterFieldType = (e: EEarlyValueNotificationType): string => {
      if (e === EEarlyValueNotificationType.CERT) {
        return "" + i18n.t("common.yes");
      } else if (e === EEarlyValueNotificationType.NO_CERT) {
        return "" + i18n.t("common.no");
      } else {
        return "";
      }
    };

    // Transactions table
    const data: IStaticData = {
      transactionsFieldGroups: [
        i18n.t("transactions.field.groupFieldTransationData").toString(),
        i18n.t("transactions.field.groupFieldEarlyValueData").toString(),
        i18n.t("transactions.field.groupFieldExtradaData").toString(),
      ],
      transactionsFieldPTable: [
        new FieldPTable({ key: "actions", type: FieldPTableType.ACTION, label: "", fixedOrder: true, width: "46px" }),
        new FieldPTable({ key: "tableKey", type: FieldPTableType.HIDDEN }),
        new FieldPTable({
          key: "transactionDate",
          type: FieldPTableType.DATE,
          label: "" + i18n.t("transactions.field.transactionDate"),
          width: "150px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showOrderField: true,
          showFilterField: true,
          formatter: localDateTimeFormatter,
          formatterForFilter: localDateFormatter,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "accountingDate",
          type: FieldPTableType.DATE,
          label: "" + i18n.t("transactions.field.accountingDate"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
          formatter: localDateTimeFormatter,
          formatterForFilter: localDateFormatter,
        }),
        new FieldPTable({
          key: "actualId",
          label: "" + i18n.t("transactions.field.actualId"),
          width: "180px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "collectionId",
          label: "" + i18n.t("transactions.field.collectionId"),
          width: "120px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "deviceDetails.deviceCode",
          label: "" + i18n.t("transactions.field.deviceId"),
          width: "170px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showOrderField: true,
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "servicePoint.name",
          label: "" + i18n.t("transactions.field.servicePointName"),
          width: "170px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showOrderField: true,
          showFilterField: true,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "entity.name",
          label: "" + i18n.t("transactions.field.entityName"),
          width: "130px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showOrderField: true,
          showFilterField: true,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "teller",
          label: "" + i18n.t("transactions.field.tellerId"),
          width: "140px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "tellerLogin",
          label: "" + i18n.t("transactions.field.tellerLogin"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showOrderField: true,
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "tellerName",
          label: "" + i18n.t("transactions.field.tellerName"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showOrderField: true,
          showFilterField: true,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "transactionType",
          width: "110px",
          label: "" + i18n.t("transactions.field.transactionType"),
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          formatter: transactionTypeFormatter,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "origin",
          label: "" + i18n.t("transactions.field.origin"),
          width: "55px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
          formatter: transactionOriginFormater,
        }),
        /**
         * Estos campos no para PMV
        new FieldPTable({
          key: "status",
          label: "" + i18n.t("transactions.field.status"),
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          show: false,
        }),
        new FieldPTable({
          key: "statusDate",
          label: "" + i18n.t("transactions.field.statusDate"),
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          show: false,
          formatter: (id: string) => format(new Date(id), "dd/MM/yyyy HH:mm:ss"),
        }),
        */
        new FieldPTable({
          key: "amount.currency",
          label: "" + i18n.t("transactions.field.currency"),
          width: "55px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "amount.numItems",
          label: "" + i18n.t("transactions.field.numItems"),
          type: FieldPTableType.NUMBER,
          width: "125px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "amount.total",
          label: "" + i18n.t("transactions.field.amount"),
          type: FieldPTableType.NUMBER,
          width: "60px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          formatter: decimalFormatter,
          showFilterField: true,
          alwaysShow: true,
        }),
        new FieldPTable({
          key: "delegation",
          label: "" + i18n.t("transactions.field.delegation"),
          width: "75px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        /**
         * Estos campos no para PMV
        new FieldPTable({
              key: "certificatedAmount",
              label: "" + i18n.t("transactions.field.certificatedAmount"),
              groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
              show: false,
            }),
         */
        new FieldPTable({
          key: "channel",
          label: "" + i18n.t("transactions.field.channel"),
          width: "50px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "receiptNumber",
          label: "" + i18n.t("transactions.field.receiptNumber"),
          width: "100px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "barCodes",
          label: "" + i18n.t("transactions.field.barCodes"),
          type: FieldPTableType.NUMBER,
          width: "120px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "strapSealCode",
          label: "" + i18n.t("transactions.field.strapSealCode"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "systemEntryDate",
          type: FieldPTableType.DATE,
          label: "" + i18n.t("transactions.field.systemEntryDate"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
          formatter: localDateTimeFormatter,
          formatterForFilter: localDateFormatter,
        }),
        new FieldPTable({
          key: "transactionRef",
          label: "" + i18n.t("transactions.field.transactionRef"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
        new FieldPTable({
          key: "transactionInfo",
          label: "" + i18n.t("transactions.field.transactionInfo"),
          width: "110px",
          groupField: "" + i18n.t("transactions.field.groupFieldTransationData"),
          showFilterField: true,
          show: false,
        }),
      ],
      transactionsEarlyValueFieldPTable: [
        new FieldPTable({
          key: "amount.accreditation.date",
          label: "" + i18n.t("transactions.field.accreditationDate"),
          width: "150px",
          groupField: "" + i18n.t("transactions.field.groupFieldEarlyValueData"),
          showOrderField: true,
          showFilterField: true,
          show: false,
          formatter: localDateTimeFormatter,
          formatterForFilter: localDateFormatter,
        }),
        new FieldPTable({
          key: "amount.accreditation.type",
          label: "" + i18n.t("transactions.field.isAccredited"),
          width: "150px",
          groupField: "" + i18n.t("transactions.field.groupFieldEarlyValueData"),
          showFilterField: true,
          show: false,
          formatter: earlyValueAccreditationTypeFormatter,
          formatterForFilter: earlyValueAccreditationTypeFormatter,
        }),
        new FieldPTable({
          key: "amount.accreditation.id",
          label: "" + i18n.t("transactions.field.earlyValueId"),
          groupField: "" + i18n.t("transactions.field.groupFieldEarlyValueData"),
          showFilterField: true,
          width: "150px",
          show: false,
        }),
        // new FieldPTable({
        //   key: "entityNameAccrediting",
        //   label: "" + i18n.t("transactions.field.entityNameAccrediting"),
        //   width: "140px",
        //   groupField: "" + i18n.t("transactions.field.groupFieldEarlyValueData"),
        //   showFilterField: true,
        //   show: false,
        // }),
        // new FieldPTable({
        //   key: "bankAccount",
        //   label: "" + i18n.t("transactions.field.bankAccount"),
        //   width: "110px",
        //   groupField: "" + i18n.t("transactions.field.groupFieldEarlyValueData"),
        //   showFilterField: true,
        //   show: false,
        // }),
      ],
    };
    context.commit("SET_STATIC_DATA", data);
  },

  async fetchOrders(context: Context): Promise<void> {
    const LIMIT_ORDERS = 5000;
    const MONTH_BEFORE_AFTER = 3;
    const nowLocalDate: LocalDate = LocalDate.now();

    const nowDateAfter3Months = new Date(nowLocalDate.plusMonths(MONTH_BEFORE_AFTER).toString());
    const nowDateBefore3Months = new Date(nowLocalDate.minusMonths(MONTH_BEFORE_AFTER).toString());

    const data = await this.$services.order.fetchOrders({
      filters: [
        {
          field: "serviceDate",
          operator: "BETWEEN",
          value: `${nowDateBefore3Months.toISOString()},${nowDateAfter3Months.toISOString()}`,
        },
      ],
      sortFields: [],
      limit: LIMIT_ORDERS,
      offset: 0,
    });
    context.commit("SET_ORDERS", data);

    const commodities = [...new Map(data.map((value) => value.commodity).map((item) => [item["code"], item])).values()];
    context.commit("SET_COMMODITIES", Array.from(commodities));

    const statues = [...new Map(data.map((value) => value.orderStatus).map((item) => [item["code"], item])).values()];
    context.commit("SET_STATUSES", Array.from(statues));

    const countries = [...new Map(data.map((value) => value.country).map((item) => [item["code"], item])).values()];
    context.commit("SET_COUNTRIES_ORDERS", Array.from(countries));
  },
  async fetchServicePointsOrders(context: Context): Promise<void> {
    const data: ServicePoint[] = await this.$services.servicePointOrders.fetchServicePoints();
    const servicePoints = data.filter((value) => value.entity && value.center);

    const centers = [
      ...new Map(servicePoints.map((value) => value.center).map((item) => [item["code"], item])).values(),
    ];
    const entities = [
      ...new Map(servicePoints.map((value) => value.entity).map((item) => [item["code"], item])).values(),
    ];

    context.commit("SET_SERVICE_POINT_ORDERS", servicePoints);
    context.commit("SET_CENTERS", Array.from(centers));
    context.commit("SET_ENTITIES", Array.from(entities));
  },
  async fetchCommodities(context: Context, id: string): Promise<void> {
    const data = await this.$services.commodities.fetchCommodities(id);
    context.commit("SET_COMMODITIES_SERVICE", data);
  },
  async fetchAssistanceFailures(context: Context, country: string): Promise<void> {
    const data = await this.$services.assistanceFailure.fetchAssistanceFailures(country);
    context.commit("SET_ASSISTANCE_FAILURES", data);
  },
  async fetchStatuses(context: Context): Promise<void> {
    const data = await this.$services.status.fetchStatuses();
    context.commit("SET_STATUSES", data);
  },
  async saveOrderCreatePickup(context: Context, order: CreateOrderPickupService): Promise<void> {
    await this.$services.order.fetchOrderCreatePickup(order);
    await context.dispatch("fetchOrders");
  },

  async saveOrderCreateDeliveryChange(context: Context, order: CreateOrderDeliveryService): Promise<void> {
    await this.$services.order.fetchOrderCreateDeliveryChange(order);
    await context.dispatch("fetchOrders");
  },

  async saveOrderCreateAssistance(context: Context, order: CreateOrderAssistanceService): Promise<void> {
    await this.$services.order.fetchOrderCreateAssistance(order);
    await context.dispatch("fetchOrders");
  },

  async saveOrderCreateSupply(context: Context, order: CreateOrderSupplyService): Promise<void> {
    await this.$services.order.fetchOrderCreateSupply(order);
    await context.dispatch("fetchOrders");
  },
};
