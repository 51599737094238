
import Icon from "@/orders/components/Icon/index.vue";
import Dropdown from "@/orders/components/Dropdown/index.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { OrderDTO, OrderSupplyData, RequestedSupply, Supply } from "@/orders";

@Component({
  components: {
    Icon,
    Dropdown,
  },
})
export default class StepSupply extends Vue {
  @Prop({ required: true }) value!: OrderSupplyData;
  @Prop({ required: true, default: null }) notChangeOrder!: OrderDTO;
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  supplies: Supply[] = [];
  materialDescription = "";
  materialType = "";
  quantity = 0;

  @Watch("value", { deep: true })
  onValueChange(): void {
    this.$emit("input", this.value);
  }

  addNewItem(): void {
    const found = this.supplies.find(
      (item) => item.supplyDescription === this.materialDescription && item.supplyTypeDescription === this.materialType
    );
    if (found) {
      const newItem: RequestedSupply = {
        supply: found,
        quantity: this.quantity,
      };
      this.value.requestedSupplies.push(newItem);
    }
    this.resetData();
  }

  async created() {
    const countryCode = this.notChangeOrder.country.code;
    this.supplies = await this.$services.order.fetchSupplies(countryCode);
  }

  get typeMaterialDescriptions() {
    const material = this.supplies.filter((item) => item.supplyDescription === this.materialDescription);
    return material.map((item) => item.supplyTypeDescription) || [];
  }

  get materialDescriptions(): string[] {
    return this.supplies.map((item) => item.supplyDescription) || [];
  }

  resetData() {
    this.materialDescription = "Seleccionar";
    this.materialType = "";
    this.quantity = 0;
  }
}
