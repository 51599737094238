
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import ComparatorInput from "@/orders/components/ComparatorInput/ComparatorInput.vue";
import Utils from "../../Commons/utils";
import PDropdown from "@/orders/components/Dropdown/index.vue";
import {
  Currency,
  Denomination,
  DenominationData,
  DenominationPack,
  OrderDeliveryData,
  OrderDTO,
} from "@/orders/entities";

@Component({
  components: {
    Icon,
    ComparatorInput,
    PDropdown,
  },
})
export default class StepDelivery extends Vue {
  // Props
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  @Prop({ required: true }) value!: OrderDeliveryData;

  // Data
  currentPack = "";
  selectCurrency = "";
  totalCoin = "0.00";
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  denominations: DenominationData[] = [];
  denominationsFromDenominationPacks: DenominationData[] = [];
  denominationPacks: DenominationPack[] = [];
  currencies: Currency[] = [];

  // Lifecycle hook
  async created(): Promise<void> {
    const countryCode = this.notChangeOrder.country.code;
    this.currencies = await this.$services.order.fetchOrderCurrencies(countryCode);
    this.selectCurrency = this.currencies[0].code;
    this.denominationPacks = await this.$services.order.fetchOrderDenominationPacks(countryCode);

    const denominations = await this.$services.order.fetchOrderDenominations(this.selectCurrency);

    this.denominationFormat(denominations);
    this.denominationPackFormat(this.denominationPacks);
  }

  get filterMonedas(): DenominationData[] {
    return this.denominations.filter((denomination) => denomination.type === "Monedas");
  }

  get filterBilletes(): DenominationData[] {
    return this.denominations.filter((denomination) => denomination.type === "Billetes");
  }

  // Watchers
  @Watch("denominations", { immediate: true, deep: true })
  onDenominationsChange(): void {
    this.updateDeliveryData();
  }

  @Watch("denominationsFromDenominationPacks", { immediate: true, deep: true })
  onDenominationPacksChange(): void {
    this.updateDeliveryData();
  }

  @Watch("selectCurrency", { deep: true })
  async onSelectCurrencyChange(): Promise<void> {
    this.denominationFormat(await this.$services.order.fetchOrderDenominations(this.selectCurrency));
  }

  updateDeliveryData(): void {
    const requestedDenominationsValue = this.denominations
      .filter((denomination) => denomination.quantity > 0 && denomination._total > 0)
      .map((denomination) => {
        return {
          quantity: denomination.quantity,
          total: denomination._total,
          denomination: denomination,
        };
      });

    const requestedDenominationPacksValue = this.denominationsFromDenominationPacks
      .filter((denomination) => denomination.quantity > 0 && denomination._total > 0)
      .map((denomination) => {
        const denominationPack = this.denominationPacks.find(
          (denominationPack) => denominationPack.currency.code === this.selectCurrency
        )!;
        return {
          quantity: denomination.quantity,
          denominationPack: denominationPack,
        };
      });
    const newValue: OrderDeliveryData = {
      requestedDenominations: requestedDenominationsValue,
      requestedDenominationPack: requestedDenominationPacksValue,
      totalPrice: this.totalPrice(),
    };
    this.$emit("input", newValue);
  }

  // Methods
  changePack(pack: string): void {
    this.currentPack = this.currentPack === pack ? "" : pack;
  }

  currencyFormat(amount: number): string {
    return Utils.currencyFormat(amount);
  }

  get currenciesCode() {
    return this.currencies.map((currency) => currency.code);
  }

  totalPrice(): number {
    return this.denominations.reduce((acc, denomination) => acc + denomination._total, 0);
  }

  addExpand({ quantity, value, id }: { quantity: number; value: number; id: string }): void {
    const current = this.denominations.findIndex((denomination) => denomination.id === id);
    this.denominations[current].quantity = quantity;
    this.denominations[current]._total = value;
    this.denominations[current].expand = false;
  }

  denominationFormat(denominations: Denomination[]): void {
    this.denominations = denominations.map((denomination) => ({
      ...denomination,
      _total: 0,
      quantity: 0,
      expand: false,
    }));
  }

  denominationPackFormat(denominationsPack: DenominationPack[]): void {
    this.denominationsFromDenominationPacks = denominationsPack
      .filter((pack) => pack.currency.code == this.selectCurrency)
      .map((pack) => pack.packAmounts)
      .flat()
      .map((packAmount) => packAmount.denomination)
      .flat()
      .map((denomination) => ({
        ...denomination,
        _total: 0,
        quantity: 0,
        expand: false,
      }));
  }

  subTotalAmount(amounts: DenominationData[]): number {
    return amounts.reduce((acc, denomination) => acc + denomination._total, 0);
  }
}
