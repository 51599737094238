
import Icon from "@/orders/components/Icon/index.vue";
import ServicePoints from "./ServicePoints.vue";
import Centers from "./Centers.vue";
import Entities from "./Entities.vue";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Center, Entity, ServicePoint } from "@/orders";

@Component({
  components: {
    Icon,
    ServicePoints,
    Centers,
    Entities,
  },
})
export class FilterOrders extends Vue {
  @Prop({ required: true }) value!: {
    servicePoints: ServicePoint[];
    centers: Center[];
    entities: Entity[];
  };
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
    if (this.valueLocal.servicePoints.length) {
      this.tabPosition = 1;
    } else if (this.valueLocal.centers.length) {
      this.tabPosition = 2;
    } else if (this.valueLocal.entities.length) {
      this.tabPosition = 3;
    }
  }

  tabPosition = 1;
  @Watch("tabPosition", { immediate: true, deep: true })
  onChangeTabPosition() {
    if (this.tabPosition == 1) {
      this.valueLocal.centers = [];
      this.valueLocal.entities = [];
    } else if (this.tabPosition == 2) {
      this.valueLocal.servicePoints = [];
      this.valueLocal.entities = [];
    } else {
      this.valueLocal.centers = [];
      this.valueLocal.servicePoints = [];
    }
  }

  eyes = require("@/orders/assets/icons/icon-eye-off.svg");
  buttonDisabled = true;
  valueLocal: {
    servicePoints: ServicePoint[];
    centers: Center[];
    entities: Entity[];
  } = { servicePoints: [], centers: [], entities: [] };

  changeFilteredPoints(val: ServicePoint[]) {
    this.buttonDisabled = val.length == 0;
    this.valueLocal = { servicePoints: val, centers: [], entities: [] };
  }
  changeFilteredCenters(val: Center[]) {
    this.buttonDisabled = val.length == 0;
    this.valueLocal = { servicePoints: [], centers: val, entities: [] };
  }
  changeFilteredEntities(val: Entity[]) {
    this.buttonDisabled = val.length == 0;
    this.valueLocal = { servicePoints: [], centers: [], entities: val };
  }

  filterOrders(): void {
    this.$emit("input", this.valueLocal);
    this.closeModal();
  }

  closeModal() {
    this.$emit("closeModal");
  }
}
export default FilterOrders;
