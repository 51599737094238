
import ModalCancelation from "@/orders/components/ModalCancelation/index.vue";
import ModalUpdate from "@/orders/components/ModalUpdate/ModalUpdate.vue";
import Icon from "@/orders/components/Icon/index.vue";
import PRating from "@/orders/components/Aside/Rating/PRating.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order, OrderStatus, RequestedDenominations } from "@/orders";
import { Getter } from "vuex-class";
import VueOrders from "@/orders/vue-orders";
import DateTimeMixin from "@/mixins/datetime";

interface DenominationFormat {
  code: string;
  symbol: string;
  total: number;
}
@Component({
  components: {
    Icon,
    PRating,
    ModalCancelation,
    ModalUpdate,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export class PAside extends Vue {
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) order!: Order;
  @Getter("getStatuses") getStatuses!: OrderStatus[];

  eyes = require("@/orders/assets/icons/icon-eye-off.svg");
  statusExpand = false;
  openCancelation = false;
  openUpdate = false;

  closedModalCancelation() {
    this.openCancelation = false;
  }

  closedModalUpdate() {
    this.openUpdate = false;
  }

  closeModal() {
    this.$emit("closeModal");
  }

  openTripulation() {
    this.$emit("openTripulation", this.order);
  }

  formatPrice(price: number): string {
    return price.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  formatStateDate(code: string, order: Order): string {
    const dateString: string = this.namedStatus(code, order).date || "";
    return dateString === "" ? "" : this.formatDateSimple(dateString);
  }
  formatDateSimple(date: string): string {
    const dateToFormat = new Date(date);
    return this.formatDateTime(dateToFormat, "dd/MM/yyyy")!;
  }

  sumTotalsByCode(items: RequestedDenominations[]): DenominationFormat[] {
    const resultMap: { [key: string]: { symbol: string; total: number } } = {};

    items.forEach((item) => {
      const { code, symbol } = item.denomination.currency;
      if (resultMap[code]) {
        resultMap[code].total += item.total;
      } else {
        resultMap[code] = { symbol, total: item.total };
      }
    });

    return Object.entries(resultMap).map(([code, { symbol, total }]) => ({ code, symbol, total }));
  }

  get statuses(): OrderStatus[] {
    return this.getStatuses;
  }
}
export default PAside;
