import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { LogicCashTodayLastTransactions, RawLogicCashTodayLastTransactions } from "@/entities";
import { LogicCashTodayTransactionsService } from "./logicCashTodayTransactions.types";

export class LogicCashTodayTransactionsServiceImpl implements LogicCashTodayTransactionsService {
  async fetchLogicCashTodayLastTransactionsByLogicCashTodayId(id: string): Promise<LogicCashTodayLastTransactions> {
    const { data } = await Vue.$axios.get<RawLogicCashTodayLastTransactions>(
      `${BACKEND_BASE_URL}/api/v1/logicCashToday/${id}/lastTransactions`
    );
    return new LogicCashTodayLastTransactions(data);
  }
}
