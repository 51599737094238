
import PDropdown from "@/orders/components/Dropdown/index.vue";
import Utils from "../../Commons/utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Commodity, Currency, OrderDTO, OrderPickupData } from "@/orders";
import { RequestedCurrencyData } from "@/orders/entities/requestedCurrency";
import Icon from "@/orders/components/Icon/index.vue";

@Component({
  components: {
    Icon,
    PDropdown,
  },
})
export default class StepPickup extends Vue {
  @Getter("getCommodities") commodities!: Commodity[];
  @Prop({ required: true }) value!: OrderPickupData;
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  currencies: Currency[] = [];
  currencyCode = "";
  quantity = 0;
  comments = "";
  divisasTotales: RequestedCurrencyData[] = [];

  @Watch("value", { deep: true })
  onChangeValue(): void {
    this.divisasTotales = [];

    const sumasPorDivisa: any = {};

    this.value.requestedCurrency.forEach((item) => {
      const code = item.currency.code;
      if (!sumasPorDivisa[code]) {
        sumasPorDivisa[code] = 0;
      }
      sumasPorDivisa[code] += Number(item.quantity);
    });

    Object.keys(sumasPorDivisa).forEach((code) => {
      const currency = this.currencies.find((c) => c.code === code);
      if (currency) {
        this.divisasTotales.push({
          currency: currency,
          quantity: sumasPorDivisa[code],
        });
      }
    });
  }

  async created() {
    this.currencies = await this.$services.order.fetchOrderCurrencies(this.notChangeOrder.country.code);
  }

  currencyFormat(amount: number) {
    return Utils.currencyFormat(amount);
  }

  get formatCurrencies(): string[] {
    return this.currencies.map((currency) => currency.code);
  }

  addNewItem() {
    if (this.currencyCode === "Seleccionar" || this.quantity === 0) {
      return;
    }
    const newRequestedCurrencyData: RequestedCurrencyData = {
      currency: this.currencies.find((currency) => currency.code === this.currencyCode) as Currency,
      quantity: this.quantity,
    };
    const exist = this.value.requestedCurrency.find(
      (item) => item.currency.code === newRequestedCurrencyData.currency.code
    );
    if (exist) {
      const obj = this.value.requestedCurrency[this.value.requestedCurrency.indexOf(exist)];
      obj.quantity = Number(newRequestedCurrencyData.quantity) + Number(obj.quantity);
    } else {
      this.value.requestedCurrency.push(newRequestedCurrencyData);
    }
    this.resetData();
  }

  resetData(): void {
    this.currencyCode = "Seleccionar";
    this.quantity = 0;
  }
}
