import { render, staticRenderFns } from "./stepPickup.vue?vue&type=template&id=cb5de336&scoped=true&"
import script from "./stepPickup.vue?vue&type=script&lang=ts&"
export * from "./stepPickup.vue?vue&type=script&lang=ts&"
import style0 from "./stepPickup.vue?vue&type=style&index=0&id=cb5de336&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5de336",
  null
  
)

export default component.exports