import { RawServicePointBalance, RawServicePointBalances, ServicePointBalance, ServicePointBalances } from "@/entities";
import { BACKEND_BASE_URL } from "@/services";
import Vue from "vue";
import { ServicePointBalanceService } from "./servicePointBalance.types";

export class ServicePointBalanceServiceImpl implements ServicePointBalanceService {
  async fetchServicePointBalances(): Promise<ServicePointBalances> {
    const { data } = await Vue.$axios.get<RawServicePointBalances>(
      `${BACKEND_BASE_URL}/api/v1/me/servicePointBalances`
    );
    return new ServicePointBalances(data);
  }

  async fetchServicePointBalanceById(id: string): Promise<ServicePointBalance> {
    const { data } = await Vue.$axios.get<RawServicePointBalance>(
      `${BACKEND_BASE_URL}/api/v1/servicePointBalances/${id}`
    );
    return new ServicePointBalance(data);
  }
}
