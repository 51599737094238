
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Order } from "@/orders/entities/order";
import { Center, Commodity, Entity, OrderStatus, ServicePoint } from "@/orders";
import Icon from "@/orders/components/Icon/index.vue";
import NavigationWeek from "@/orders/components/NavigationWeek/index.vue";
import OptionsCalendar from "@/orders/components/OptionsCalendar/index.vue";
import CardDetail from "@/orders/components/CardDetail/index.vue";
import FilterOrders from "@/orders/components/FiltersOrders/index.vue";
import WeekConfig from "@/orders/components/WeekConfig/index.vue";
import VueMethods from "@/vue-methods";
import PendingOrders from "@/orders/components/Pending/PendingOrders.vue";
import PAside from "@/orders/components/Aside/PAside.vue";
import VueOrders from "@/orders/vue-orders";
import Tripulation from "@/orders/components/Tripulation/index.vue";
import ModalCreate from "@/orders/components/ModalCreate/ModalCreate.vue";

@Component({
  components: {
    ModalCreate,
    Icon,
    NavigationWeek,
    OptionsCalendar,
    CardDetail,
    FilterOrders,
    WeekConfig,
    PendingOrders,
    PAside,
    Tripulation,
  },
  mixins: [VueMethods, VueOrders],
})
export class DayViewPage extends Vue {
  @Getter("getOrders") getOrders!: Order[];
  @Getter("getServicePointsOrders") getServicePoints!: ServicePoint[];
  @Getter("getStatuses") getStatuses!: OrderStatus[];
  @Getter("getCommodities") getCommodities!: Commodity[];
  plus = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  eyesOff = require("@/orders/assets/icons/icon-eye-off.svg");
  eyes = require("@/orders/assets/icons/icon-eye.svg");
  filter = require("@/orders/assets/icons/icon-filtrar.svg");
  prosegur = require("@/orders/assets/icons/icon-prosegur.svg");
  setting = require("@/orders/assets/icons/icon-settings.svg");
  right = require("@/orders/assets/icons/icon-chevron-right.svg");
  pedidos = require("@/orders/assets/icons/icon-pedidos-entrega-de-cambio.svg");
  notification = require("@/orders/assets/icons/diferencia-icon.svg");

  filterComoddities: string[] = [];
  filterStatutes: string[] = [];
  orderSelected: Order | null = null;
  openModal = false;
  openModalCreate = false;
  openModalFilter = false;
  openModalWeek = false;
  openModalPending = false;
  openModalTripulation = false;
  openFilters = false;
  toggleFilterService = false;
  toggleFilterState = false;
  showNotOrders = false;
  configView: {
    startDay: string;
    sortBy: string;
  } = { startDay: "sunday", sortBy: "code" };
  date = new Date();
  filtersOrders: {
    servicePoints: ServicePoint[];
    centers: Center[];
    entities: Entity[];
  } = { servicePoints: [], centers: [], entities: [] };

  dateFormated(fecha: string): string {
    const date = this.dateFromString(fecha);
    const month = date.toLocaleDateString("es-ES", { month: "long" });
    const day = date.toLocaleDateString("es-ES", { weekday: "long" });
    return day + ", " + date.getDate() + " " + month;
  }

  get ordersPending(): Order[] {
    return this.getOrders.filter((item) => item.orderStatus.code === "PEN");
  }

  get servicePointsFiltered() {
    if (this.filtersOrders.servicePoints.length) {
      return this.getServicePoints.filter((value) => this.filtersOrders.servicePoints.includes(value));
    } else if (this.filtersOrders.centers.length) {
      return this.getServicePoints.filter((value) =>
        this.filtersOrders.centers.map((value1) => value1.code).includes(value.center.code)
      );
    } else if (this.filtersOrders.entities.length) {
      return this.getServicePoints.filter((value) =>
        this.filtersOrders.entities.map((value1) => value1.code).includes(value.entity.code)
      );
    }
    return this.getServicePoints;
  }

  get orderedPoints(): ServicePoint[] {
    let returnValue = [];
    if (this.showNotOrders) {
      returnValue = this.servicePointsFiltered.filter((point: ServicePoint) => {
        for (let i = 0; i < this.getOrders.length; i++) {
          if (point.id === this.getOrders[i].servicePoint.id) {
            return point;
          }
        }
      });
    } else {
      returnValue = this.servicePointsFiltered;
    }
    return returnValue.sort((a, b) => {
      try {
        return this.sortAscendingIgnoreCase(a, b, this.configView.sortBy);
      } catch {
        return this.sortAscending(a, b, this.configView.sortBy);
      }
    });
  }

  mounted(): void {
    if (localStorage.configWeek) {
      this.configView = JSON.parse(localStorage.configWeek);
    }
    setTimeout(() => {
      this.getCommodities.forEach((value) => this.filterComoddities.push(value.code));
      this.getStatuses.forEach((value) => this.filterStatutes.push(value.code));
    }, 1000);
  }

  getOrderFromDay(servicepoint: ServicePoint): Order[] {
    return this.getOrders
      .filter((order) => order.commodity.name !== "SUPPLY" && order.serviceDate !== null)
      .filter((order) => {
        const orderDate = this.dateFromString(order.serviceDate);
        if (
          servicepoint.id === order.servicePoint.id &&
          (this.filterComoddities.length == 0 || this.filterComoddities.includes(order.commodity.code)) &&
          (this.filterStatutes.length == 0 || this.filterStatutes.includes(order.orderStatus.code))
        ) {
          return orderDate.getDate() === this.date.getDate() && orderDate.getMonth() === this.date.getMonth();
        }
      });
  }

  dateFromString(dateString: string): Date {
    const index = dateString.indexOf("[");
    if (index >= 0) return new Date(dateString.slice(0, index));
    return new Date(dateString);
  }

  changeOrderSelected(order: Order) {
    this.orderSelected = order;
    this.openModal = true;
  }

  isToday(date: Date) {
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  dayMonth() {
    return this.date.getDate() + " " + this.date.toLocaleDateString("es-ES", { month: "long" });
  }
  dayWeek() {
    return this.date.toLocaleDateString("es-ES", { weekday: "long" });
  }

  showTripulation(order: Order): void {
    this.orderSelected = order;
    this.openModalTripulation = true;
  }
}
export default DayViewPage;
