import { BACKEND_ORDERS_BASE_URL, BACKEND_ORDERS_WRITES_BASE_URL } from "@/services";
import Vue from "vue";
import {
  CancellationOrder,
  CancellationReason,
  Denomination,
  DenominationPack,
  Frequency,
  Order,
  OrderService,
  RawCancellationOrder,
  RawCancellationReason,
  RawDenomination,
  RawDenominationPack,
  RawFrequency,
  RawOrders,
  RawSupply,
  UpdateOrderAssistance,
} from "@/orders";

export class OrderServiceImpl implements OrderService {
  async fetchOrders(map: Record<string, unknown>): Promise<Order[]> {
    const { data } = await Vue.$axios.post<RawOrders>(`${BACKEND_ORDERS_BASE_URL}/api/v1/orders/view`, map);
    return data.orders.map((value) => {
      return new Order(value);
    });
  }
  async fetchOrderCreateAssistance(order: any): Promise<any> {
    await Vue.$axios.post<any>(`${BACKEND_ORDERS_WRITES_BASE_URL}/api/v1/orders/assistance-services`, order);
  }
  async fetchOrderCreateSupply(order: any): Promise<any> {
    await Vue.$axios.post<any>(`${BACKEND_ORDERS_WRITES_BASE_URL}/api/v1/orders/supply-services `, order);
  }
  async fetchOrderCreatePickup(order: any): Promise<any> {
    await Vue.$axios.post<any>(`${BACKEND_ORDERS_WRITES_BASE_URL}/api/v1/orders/pickup-services `, order);
  }
  async fetchOrderCreateDeliveryChange(order: any): Promise<any> {
    await Vue.$axios.post<any>(`${BACKEND_ORDERS_WRITES_BASE_URL}/api/v1/orders/delivery-services`, order);
  }
  async fetchOrderCurrencies(country: string): Promise<any> {
    const { data } = await Vue.$axios.get<any>(`${BACKEND_ORDERS_BASE_URL}/api/v1/currencies/country/${country}`);
    return data;
  }
  async fetchOrderDenominations(currency: string): Promise<Denomination[]> {
    const { data } = await Vue.$axios.get<RawDenomination[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/denominations/currency/${currency}`
    );
    return data.map((value) => new Denomination(value));
  }
  async fetchOrderDenominationPacks(country: string): Promise<DenominationPack[]> {
    const { data } = await Vue.$axios.get<RawDenominationPack[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/denomination-packs/country/${country}`
    );
    return data.map((value) => new DenominationPack(value));
  }
  async fetchSupplies(country: string): Promise<RawSupply[]> {
    const { data } = await Vue.$axios.get<RawSupply[]>(`${BACKEND_ORDERS_BASE_URL}/api/v1/supplies/country/${country}`);
    return data;
  }
  async fetchFrequencies(contractCode: string): Promise<Frequency> {
    const { data } = await Vue.$axios.get<RawFrequency>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/frequencies/contract/${contractCode}`
    );
    return data;
  }
  async fetchCancellationReasons(countryCode: string): Promise<CancellationReason[]> {
    const { data } = await Vue.$axios.get<RawCancellationReason[]>(
      `${BACKEND_ORDERS_BASE_URL}/api/v1/cancellation-reasons/country/${countryCode}`
    );
    return data.map((value) => new CancellationReason(value));
  }
  async patchOrderCancellation(order: CancellationOrder): Promise<CancellationOrder> {
    const { data } = await Vue.$axios.patch<RawCancellationOrder>(
      `${BACKEND_ORDERS_WRITES_BASE_URL}/api/v1/orders/order-cancellations`,
      order
    );
    return data;
  }
  async patchOrderAssistance(updateOrderAssistance: UpdateOrderAssistance): Promise<any> {
    await Vue.$axios.patch<any>(
      `${BACKEND_ORDERS_WRITES_BASE_URL}/api/v1/orders/${updateOrderAssistance.orderId}/assistance-services`,
      updateOrderAssistance
    );
  }
}
