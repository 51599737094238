import { DenominationPack } from "@/orders/entities/denominationPack";
import { RawRequestedDenominationPack } from "@/orders/entities";

export class RequestedDenominationPack {
  readonly quantity: number;
  readonly denominationPack: DenominationPack;

  constructor(data: RawRequestedDenominationPack) {
    this.quantity = data.quantity;
    this.denominationPack = new DenominationPack(data.denominationPack);
  }
}

export interface CreateRequestedDenominationPack {
  denominationPackId: string;
  quantity: number;
}
