import {
  AssistanceFailure,
  Center,
  Commodity,
  Country,
  Delegation,
  Entity,
  OrderStatus,
  Rating,
  RawOrder,
  RequestedDenominationPack,
  RequestedDenominations,
  RequestedSupply,
  ServicePoint,
} from "@/orders/entities";
import { RequestedCurrency } from "@/orders/entities/requestedCurrency";
import DateTimeMixin from "@/mixins/datetime";

interface CreateUserOrder {
  email: string;
  id: string;
  name: string;
}

export interface UpdateOrderAssistance {
  orderId: string;
  assistanceFailureId: string;
  comments: string;
  serviceDate: string;
  requestInitialTime: string;
  requestFinalTime: string;
}

export class Order {
  readonly orderId: string;
  readonly groupId: string;
  readonly origin: string;
  readonly serviceDate: string;
  readonly requestInitialTime: string;
  readonly requestFinalTime: string;
  readonly country: Country;
  readonly entity: Entity;
  readonly center?: Center;
  readonly delegation?: Delegation;
  readonly servicePoint: ServicePoint;
  readonly comments: string;
  readonly commodity: Commodity;
  readonly requestedDenominations: RequestedDenominations[];
  readonly requestedDenominationPack: RequestedDenominationPack[];
  readonly requestedSupply: RequestedSupply[];
  readonly assistanceFailures?: AssistanceFailure;
  readonly requestedCurrencies: RequestedCurrency[];
  readonly creationDate: string;
  readonly creationUser: CreateUserOrder;
  readonly modificationDate: string;
  readonly modificationUser?: CreateUserOrder;
  readonly hasServiceDate: boolean;
  readonly hasCitLimitDate: boolean;
  readonly citLimitDate: string;
  readonly orderStatus: OrderStatus;
  readonly orderCode: string;
  readonly citOrderNumber: string;
  readonly citDocument: string;
  readonly requestApprovalUser: boolean;
  readonly approvalUser: string;
  readonly approvalDate: string;
  readonly cancellationDate: string;
  readonly citConfirmDate: string;
  readonly citDepartDate: string;
  readonly citPlanningDate: string;
  readonly citDate: string;
  readonly citExecutedDate: string;
  readonly processingDate: string;
  readonly rescheduled: boolean;
  readonly withIncidents: boolean;
  readonly cancellationReason: string;
  readonly cancellationComments: string;
  readonly cancellationUser: string;
  readonly rating?: Rating;
  readonly hasPickupDeclared: boolean;

  constructor(data: RawOrder) {
    const dateTimeFormatter = new DateTimeMixin();
    this.orderId = data.orderId;
    this.groupId = data.groupId;
    this.origin = data.origin;
    this.serviceDate = dateTimeFormatter.formatISODateToCurrentZone(data.serviceDate);
    this.requestInitialTime = dateTimeFormatter.formatISODateToCurrentZone(data.requestInitialTime);
    this.requestFinalTime = dateTimeFormatter.formatISODateToCurrentZone(data.requestFinalTime);
    this.country = new Country(data.country);
    this.entity = new Entity(data.entity);
    this.center = data.center ? new Center(data.center) : undefined;
    this.delegation = data.delegation ? new Delegation(data.delegation) : undefined;
    this.servicePoint = new ServicePoint(data.servicePoint);
    this.comments = data.comments;
    this.commodity = new Commodity(data.commodity);
    this.requestedDenominations = data.requestedDenominations.map((value) => new RequestedDenominations(value));
    this.requestedDenominationPack = data.requestedDenominationPack.map(
      (value) => new RequestedDenominationPack(value)
    );
    this.requestedSupply = data.requestedSupply.map((value) => new RequestedSupply(value));
    this.assistanceFailures = data.assistanceFailures ? new AssistanceFailure(data.assistanceFailures) : undefined;
    this.requestedCurrencies = data.requestedCurrencies.map((value) => new RequestedCurrency(value));
    this.creationDate = dateTimeFormatter.formatISODateToCurrentZone(data.creationDate);
    this.creationUser = data.creationUser;
    this.modificationDate = dateTimeFormatter.formatISODateToCurrentZone(data.modificationDate);
    this.modificationUser = data.modificationUser ? data.modificationUser : undefined;
    this.hasServiceDate = data.hasServiceDate;
    this.hasCitLimitDate = data.hasCitLimitDate;
    this.citLimitDate = dateTimeFormatter.formatISODateToCurrentZone(data.citLimitDate);
    this.orderStatus = new OrderStatus(data.orderStatus);
    this.orderCode = data.orderCode;
    this.citOrderNumber = data.citOrderNumber;
    this.citDocument = data.citDocument;
    this.requestApprovalUser = data.requestApprovalUser;
    this.approvalUser = data.approvalUser;
    this.approvalDate = dateTimeFormatter.formatISODateToCurrentZone(data.approvalDate);
    this.cancellationDate = dateTimeFormatter.formatISODateToCurrentZone(data.cancellationDate);
    this.citConfirmDate = dateTimeFormatter.formatISODateToCurrentZone(data.citConfirmDate);
    this.citDepartDate = dateTimeFormatter.formatISODateToCurrentZone(data.citDepartDate);
    this.citPlanningDate = dateTimeFormatter.formatISODateToCurrentZone(data.citPlanningDate);
    this.citDate = dateTimeFormatter.formatISODateToCurrentZone(data.citDate);
    this.citExecutedDate = dateTimeFormatter.formatISODateToCurrentZone(data.citExecutedDate);
    this.processingDate = dateTimeFormatter.formatISODateToCurrentZone(data.processingDate);
    this.rescheduled = data.rescheduled;
    this.withIncidents = data.withIncidents;
    this.cancellationReason = data.cancellationReason;
    this.cancellationComments = data.cancellationComments;
    this.cancellationUser = data.cancellationUser;
    this.rating = data.rating ? new Rating(data.rating) : undefined;
    this.hasPickupDeclared = data.hasPickupDeclared;
  }
}
