
import { Vue, Component, Prop } from "vue-property-decorator";
import { Order, IRatingType, ICreateRating } from "@/orders";

@Component({
  components: {},
})
export class PRating extends Vue {
  @Prop({ required: true }) order!: Order;
  listRatingTypes: IRatingType[] = [];
  rating: ICreateRating | null = null;
  ratingType: IRatingType | null = null;
  shouldSend = false;
  hideComments = false;
  ratingSelected = -1;
  ratingComments = "";

  async created() {
    const res = await this.$services.ratingTypes.fetchRatingTypes();
    res.map((ratingType) => {
      const obj = {
        ...ratingType,
        image: require(`@/orders/assets/icons/icon-star-inactive.svg`),
      };
      this.listRatingTypes.push(obj);
    });
  }
  selectRatingType(ratingType: IRatingType, index: number): void {
    this.ratingType = {
      id: ratingType.id,
      description: ratingType.description,
      value: ratingType.value,
    };
    this.ratingSelected = index;
    this.shouldSend = true;
    this.listRatingTypes.map((ratingType, i) => {
      if (i <= index) {
        ratingType.image = require(`@/orders/assets/icons/icon-star.svg`);
      } else {
        ratingType.image = require(`@/orders/assets/icons/icon-star-inactive.svg`);
      }
    });
  }
  sendRating() {
    this.rating = {
      orderId: this.order.orderId,
      ratingTypeId: this.ratingType!.id,
      comments: this.ratingComments,
    };
  }
}

export default PRating;
