
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Dropdown extends Vue {
  // Props
  @Prop({ type: String, default: "" }) value!: string;
  @Prop({ type: Array, default: () => [] }) options!: string[];

  // Data
  isOpen = false;
  selectedOption: string = this.value || "Seleccionar";

  // Watchers
  @Watch("value")
  onValueChanged(newValue: string): void {
    this.selectedOption = newValue;
  }

  // Lifecycle hooks
  mounted(): void {
    document.addEventListener("click", this.closeDropdownOnClickOutside);
  }

  destroyed(): void {
    document.removeEventListener("click", this.closeDropdownOnClickOutside);
  }

  // Methods
  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  selectOption(event: Event): void {
    const selectedText = (event.target as HTMLElement).textContent;
    if (selectedText && selectedText !== "Seleccionar") {
      // Evita cambiar el texto del input al hacer clic en "Seleccionar"
      this.selectedOption = selectedText;
      this.$emit("input", selectedText); // Emite el evento para actualizar el valor v-model
      this.$emit("change", selectedText); // Emite el evento para actualizar el valor v-model
    }
    this.isOpen = false; // Cierra el dropdown después de seleccionar una opción
  }

  closeDropdownOnClickOutside(event: Event): void {
    if (this.$refs.dropdown && !(this.$refs.dropdown as HTMLElement).contains(event.target as Node)) {
      this.isOpen = false;
    }
  }
}
