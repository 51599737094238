
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ComparatorInput extends Vue {
  @Prop({ type: Number, required: true }) numberReference!: number;
  @Prop({ type: String, required: true }) id!: string;

  quantity = 0;
  value = 0;
  isRoundedQuantity = false;
  isRoundedValue = false;

  get roundedQuantity() {
    return Math.round(this.quantity / this.numberReference);
  }

  get roundedValue() {
    const rounded = Math.ceil(this.value / this.numberReference) * this.numberReference;
    this.isRoundedValue = rounded !== this.value;
    return rounded;
  }

  updateValue(field: string) {
    if (field === "quantity") {
      // Update value when quantity changes
      this.value = this.quantity * this.numberReference;
    } else if (field === "value") {
      // Update quantity when value changes
      let newValue = Math.round(this.value / this.numberReference);
      if (newValue === 0) newValue = 1; // Ensure minimum value is 1
      this.quantity = newValue;
    }
  }
  exit() {
    this.$emit("exit");
  }
  add() {
    this.$emit("add", { quantity: this.quantity, value: this.roundedValue, id: this.id });
  }
}
