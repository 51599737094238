import { Currency } from "@/orders/entities/currency";
import { RawDenomination } from "@/orders/entities";

export class Denomination {
  readonly id: string;
  readonly code: string;
  readonly type: string;
  readonly description: string;
  readonly value: number;
  readonly currency: Currency;

  constructor(data: RawDenomination) {
    this.id = data.id;
    this.code = data.code;
    this.type = data.type;
    this.description = data.description;
    this.value = data.value;
    this.currency = new Currency(data.currency);
  }
}

export interface DenominationData extends Denomination {
  _total: number;
  quantity: number;
  expand: boolean;
}
