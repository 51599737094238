
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Icon from "@/orders/components/Icon/index.vue";
import stepOne from "./stepOne.vue";
import stepTwo from "./stepTwo.vue";
import stepThree from "./stepThree.vue";
import stepFour from "./stepFour.vue";
import stepFive from "./stepFive.vue";
import VueOrders from "@/orders/vue-orders";
import {
  AssistanceFailure,
  Commodity,
  CreateOrderAssistanceData,
  CreateOrderAssistanceService,
  CreateOrderData,
  CreateOrderDeliveryData,
  CreateOrderDeliveryService,
  CreateOrderPickupData,
  CreateOrderPickupService,
  CreateOrderSupplyData,
  CreateOrderSupplyService,
  FactoryCreateOrder,
  OrderDateDTO,
  OrderDTO,
  ServicePoint,
} from "@/orders/entities";
import DateTimeMixin from "@/mixins/datetime";

@Component({
  components: {
    Icon,
    stepOne,
    stepTwo,
    stepThree,
    stepFour,
    stepFive,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export default class ModalCreate extends Vue {
  private readonly patternToResume = `dd MMM yyyy`;
  @Getter("getAssistanceFailures") assistanceFailures!: AssistanceFailure[];
  @Getter("getServicePointsOrders") servicePointsOrders!: ServicePoint[];
  @Action("saveOrderCreatePickup") saveOrderCreatePickup!: (order: CreateOrderPickupService) => Promise<void>;
  @Action("saveOrderCreateDeliveryChange") saveOrderCreateDeliveryChange!: (
    order: CreateOrderDeliveryService
  ) => Promise<void>;
  @Action("saveOrderCreateAssistance") saveOrderCreateAssistance!: (
    order: CreateOrderAssistanceService
  ) => Promise<void>;
  @Action("saveOrderCreateSupply") saveOrderCreateSupply!: (order: CreateOrderSupplyService) => Promise<void>;
  @Prop({ required: true }) active!: boolean;

  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  iconClose: string = require("@/orders/assets/icons/icon-x.svg");
  down: string = require("@/orders/assets/icons/icon-chevron-down.svg");
  stepPosition = 1;
  servicePointSelected = null as ServicePoint | null;
  commoditySelected = null as Commodity | null;
  order = {} as OrderDTO;
  orderDate = {} as OrderDateDTO;
  sendOrders: OrderDTO[] = [];

  fetchCommodities(data: string): void {
    this.$store.dispatch("fetchCommodities", data);
  }

  editStep(order: OrderDTO): void {
    this.stepPosition = 4;
    this.order = order;
  }
  removeOrder(order: OrderDTO): void {
    this.sendOrders = this.sendOrders.filter((item) => item.commodity !== order.commodity);
  }

  addOrder(commodity: Commodity): void {
    this.stepPosition = 4;
    const servicePoint = this.order.servicePoint;
    const country = this.order.country;

    this.order = {} as OrderDTO;
    this.order.commodity = commodity;
    this.order.country = country;
    this.order.servicePoint = servicePoint;
  }

  async submitOrders(sendOrders: OrderDTO[]): Promise<void> {
    for (const order of sendOrders) {
      await this.createOrder(order);
    }
    this.closeModal();
    this.sendMessageFromSubmittedOrders(sendOrders);
  }

  private sendMessageFromSubmittedOrders(sendOrders: OrderDTO[]): void {
    const createdOrdersMessages: string[] = [];

    const orderHasSUPPLY = sendOrders.some((order) => "SUPPLY" === order.commodity.name);

    if (sendOrders.length === 1) {
      if (orderHasSUPPLY) {
        createdOrdersMessages.push(this.$t("orders.creation.message.onlySupplyService").toString());
        this.$emit("createdOrdersMessages", createdOrdersMessages);
        return;
      }

      createdOrdersMessages.push(this.$t("orders.creation.message.anyOrderService").toString());
      this.$emit("createdOrdersMessages", createdOrdersMessages);
      return;
    }

    createdOrdersMessages.push(this.$t("orders.creation.message.anyOrderService").toString());

    if (orderHasSUPPLY) {
      createdOrdersMessages.push(this.$t("orders.creation.message.anyOrderServiceWithSupply").toString());
    }

    this.$emit("createdOrdersMessages", createdOrdersMessages);
  }

  async createOrder(order: OrderDTO): Promise<void> {
    const commodityName = order.commodity.name;
    if (order && commodityName === "PICKUP") {
      const data = order as OrderDTO;
      const pickup = data.createOrder! as CreateOrderPickupData;
      const prepare = FactoryCreateOrder.buildCreateOrderPickupService(pickup, data.servicePoint.id);

      await this.saveOrderCreatePickup(prepare);
    } else if (commodityName === "DELIVERY_CHANGE" || commodityName === "DELIVERY") {
      const data = order as OrderDTO;
      const delivery = data.createOrder! as CreateOrderDeliveryData;
      const prepare = FactoryCreateOrder.buildCreateOrderDeliveryService(delivery, data.servicePoint.id);

      await this.saveOrderCreateDeliveryChange(prepare);
    } else if (commodityName === "ASSISTANCE") {
      const data = order as OrderDTO;
      const assistance = data.createOrder! as CreateOrderAssistanceData;
      const prepare = FactoryCreateOrder.buildCreateOrderAssistanceService(assistance, data.servicePoint.id);

      await this.saveOrderCreateAssistance(prepare);
    } else if (commodityName === "SUPPLY") {
      const data = order as OrderDTO;
      const supply = data.createOrder! as CreateOrderSupplyData;
      const prepare = FactoryCreateOrder.buildCreateOrderSupplyService(supply, data.servicePoint.id);

      await this.saveOrderCreateSupply(prepare);
    }
  }

  closeModal(): void {
    this.stepPosition = 1;
    this.$emit("closeModal");
  }

  get formattedDateSelected(): string {
    return this.formatDateTime(this.orderDate.serviceDate!, this.patternToResume)!;
  }

  nextPage(): void {
    if (this.stepPosition === 2 && this.commoditySelected?.name === "SUPPLY") {
      this.stepPosition = 4;
    } else {
      this.stepPosition = this.stepPosition + 1;
    }
  }

  prevPage(): void {
    if (this.stepPosition === 4 && this.commoditySelected?.name === "SUPPLY") {
      this.stepPosition = 2;
    } else {
      this.stepPosition = this.stepPosition - 1;
    }
  }

  selectPoint(item: ServicePoint): void {
    this.servicePointSelected = item;
    this.order.servicePoint = item;
    this.order.country = item.country;
    this.fetchCommodities(this.servicePointSelected.id);
    this.nextPage();
  }

  selectCommodity(item: Commodity): void {
    this.commoditySelected = item;
    this.order.commodity = item;
    this.nextPage();
  }

  selectDate(orderDate: OrderDateDTO): void {
    this.orderDate = orderDate;
    this.nextPage();
  }

  async prepareOrder({ commodity, body }: { commodity: string; body: any }): Promise<void> {
    if (commodity === "ASSISTANCE") {
      const assistance = body as CreateOrderAssistanceData;
      const toSaveOrder = this.savedOrder(assistance);

      this.pushOrder(toSaveOrder);
    } else if (commodity === "SUPPLY") {
      const supply = body as CreateOrderSupplyData;
      const toSavedOrder = this.savedOrder(supply);

      this.pushOrder(toSavedOrder);
    } else if (commodity === "PICKUP") {
      const pickup = body as CreateOrderPickupData;
      const toSaveOrder = this.savedOrder(pickup);

      this.pushOrder(toSaveOrder);
    } else if (commodity === "DELIVERY_CHANGE" || commodity === "DELIVERY") {
      const delivery = body as CreateOrderDeliveryData;
      const toSavedOrder = this.savedOrder(delivery);

      this.pushOrder(toSavedOrder);
    }

    this.nextPage();
  }

  pushOrder(order: OrderDTO): void {
    const indexOrder = this.sendOrders.findIndex((item) => item.commodity === order.commodity);
    if (indexOrder !== -1) {
      this.sendOrders[indexOrder] = order;
    } else {
      this.sendOrders.push(order);
    }
  }

  savedOrder(obj: CreateOrderData): OrderDTO {
    return {
      ...this.order,
      createOrder: {
        ...this.orderDate,
        ...obj,
      },
    };
  }
}
