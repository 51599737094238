
import { Component, Prop, Vue } from "vue-property-decorator";
import StepFive from "@/orders/components/ModalCreate/stepFive.vue";
import StepFour from "@/orders/components/ModalCreate/stepFour.vue";
import StepThree from "@/orders/components/ModalCreate/stepThree.vue";
import Icon from "@/orders/components/Icon/index.vue";
import {
  Commodity,
  EditOrder,
  Order,
  OrderAssistanceData,
  OrderDateDTO,
  OrderDTO,
  OrderProgrammingHourType,
} from "@/orders";
import DateTimeMixin from "@/mixins/datetime";

@Component({
  components: {
    StepFive,
    StepFour,
    StepThree,
    Icon,
  },
  mixins: [DateTimeMixin],
})
export default class ModalUpdate extends Vue {
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) order!: Order;

  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  iconClose: string = require("@/orders/assets/icons/icon-x.svg");
  stepPosition = 3;
  serviceDate = {} as Date;
  sendOrders: EditOrder[] = [];
  orderDate = {} as OrderDateDTO;
  notChangeOrder = {} as OrderDTO;
  updateOrder = "update";

  created(): void {
    const _order = this.order;
    this.notChangeOrder = {
      commodity: _order.commodity,
      servicePoint: _order.servicePoint,
      country: _order.country,
    };
    this.orderDate = this.setUpOrderDate();
    const commodity = this.notChangeOrder.commodity;

    if (commodity.name === "PICKUP") {
      this.notChangeOrder.createOrder = {
        comments: _order.comments,
        serviceDate: _order.serviceDate,
        requestInitialTime: _order.requestInitialTime,
        requestFinalTime: _order.requestFinalTime,
        servicePointId: _order.servicePoint.id,
        requestedCurrency: _order.requestedCurrencies,
        hasPickupDeclared: _order.hasPickupDeclared,
      };
    } else if (commodity.name === "ASSISTANCE") {
      this.notChangeOrder.createOrder = {
        comments: _order.comments,
        serviceDate: _order.serviceDate,
        requestInitialTime: _order.requestInitialTime,
        requestFinalTime: _order.requestFinalTime,
        servicePointId: _order.servicePoint.id,
        assistanceFailuresId: _order.assistanceFailures!.id,
      };
    }
  }

  closeModal(): void {
    this.stepPosition = 3;
    this.$emit("closeModal");
  }

  get commoditySelected(): Commodity {
    return this.order.commodity;
  }

  selectDate(orderDate: OrderDateDTO): void {
    this.orderDate = orderDate;
    this.nextPage();
  }

  completeStep(data: { commodity: Commodity; body: never }): void {
    if (data.commodity.name === "ASSISTANCE") {
      const assistance: OrderAssistanceData = data.body;
      const updateOrder: EditOrder = {
        id: this.order.orderId,
        comments: "assistance.comments",
        assistanceFailureId: assistance.assistanceFailuresId,
        commodity: data.commodity,
      };
      this.sendOrders.push(updateOrder);
    }
    if (data.commodity.name === "PICKUP") {
      const updateOrder: EditOrder = {
        id: this.order.orderId,
        comments: "",
        commodity: data.commodity,
      };
      this.sendOrders.push(updateOrder);
    }
    this.nextPage();
  }

  nextPage(): void {
    this.stepPosition = this.stepPosition + 1;
  }

  prevPage(): void {
    this.stepPosition = this.stepPosition - 1;
  }

  submitOrders(): void {
    this.closeModal();
  }

  setUpOrderDate(): OrderDateDTO {
    const date = new Date(this.order.serviceDate);
    const initialDate = new Date(this.order.requestInitialTime);
    const finalDate = new Date(this.order.requestFinalTime);
    return new OrderDateDTO(date, initialDate, finalDate, OrderProgrammingHourType.INDICATE_DAY);
  }
}
