
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import StepAssistance from "@/orders/components/ModalCreate/FourStep/stepAssistance.vue";
import StepDelivery from "@/orders/components/ModalCreate/FourStep/stepDelivery.vue";
import StepSupply from "@/orders/components/ModalCreate/FourStep/stepSupply.vue";
import StepPickup from "@/orders/components/ModalCreate/FourStep/stepPickup.vue";
import VueOrders from "@/orders/vue-orders";
import {
  OrderAssistanceData,
  CreateOrderAssistanceData,
  CreateOrderDeliveryData,
  CreateOrderPickupData,
  OrderDeliveryData,
  OrderDTO,
  OrderPickupData,
  OrderSupplyData,
  CreateOrderSupplyData,
} from "@/orders/entities";

@Component({
  components: {
    StepDelivery,
    StepAssistance,
    StepSupply,
    StepPickup,
    Icon,
  },
  mixins: [VueOrders],
})
export default class StepFour extends Vue {
  // Props
  @Prop({ required: true }) notChangeOrder!: OrderDTO;
  // Data
  left: string = require("@/orders/assets/icons/icon-chevron-left.svg");
  pickup = {} as OrderPickupData;
  assistanceFailure = {} as OrderAssistanceData;
  deliveryData = {} as OrderDeliveryData;
  supply = {} as OrderSupplyData;
  comments = "";
  //

  created(): void {
    const commodity = this.notChangeOrder.commodity;
    const order = this.notChangeOrder.createOrder;
    this.comments = order?.comments || "";

    if (commodity.name === "PICKUP") {
      const pickup = order as CreateOrderPickupData;
      this.pickup.requestedCurrency = pickup?.requestedCurrency || [];
    } else if (commodity.name === "ASSISTANCE") {
      const assistance = order as CreateOrderAssistanceData;
      this.assistanceFailure = {
        assistanceFailuresId: assistance?.assistanceFailuresId || "",
      };
    } else if (commodity.name === "DELIVERY" || commodity.name === "DELIVERY_CHANGE") {
      const delivery = order as CreateOrderDeliveryData;
      this.deliveryData = {
        requestedDenominationPack: delivery?.requestedDenominationPack || [],
        requestedDenominations: delivery?.requestedDenominations || [],
        totalPrice: delivery?.requestedDenominations.reduce((acc, curr) => acc + curr.total, 0) || 0,
      };
    } else if (commodity.name === "SUPPLY") {
      const supply = order as CreateOrderSupplyData;
      this.supply = {
        requestedSupplies: supply?.requestedSupplies || [],
      };
    }
  }

  // Métodos
  prevPage(): void {
    this.$emit("prevPage", true);
  }

  completeStep(): void {
    const commodity = this.notChangeOrder.commodity;
    if (commodity) {
      // fin del paso 4
      const commodityName = commodity.name;
      if (commodityName === "PICKUP") {
        const body: Partial<CreateOrderPickupData> = {
          requestedCurrency: this.pickup.requestedCurrency,
          hasPickupDeclared: this.pickup.undeclaredAmount || true,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "ASSISTANCE") {
        const body: Partial<CreateOrderAssistanceData> = {
          assistanceFailuresId: this.assistanceFailure.assistanceFailuresId,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "DELIVERY" || commodityName === "DELIVERY_CHANGE") {
        const body: Partial<CreateOrderDeliveryData> = {
          requestedDenominationPack: this.deliveryData.requestedDenominationPack,
          requestedDenominations: this.deliveryData.requestedDenominations,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      } else if (commodityName === "SUPPLY") {
        const body: Partial<CreateOrderSupplyData> = {
          requestedSupplies: this.supply.requestedSupplies,
          comments: this.comments,
        };
        this.$emit("completeStep", { commodity: commodityName, body: body });
      }
    }
  }
}
