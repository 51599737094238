import { Component, Vue } from "vue-property-decorator";
import { Order } from "@/orders";

interface CommodityFormat {
  name: string;
  icon: string;
}
interface StatusFormat {
  description: string;
  icon: string;
  name: string;
  date?: string;
}

declare module "vue/types/vue" {
  interface Vue {
    namedCommodities(name: string): CommodityFormat;
    namedStatus(code: string, order?: Order): StatusFormat;
  }
}

@Component({
  name: "vue.orders",
})
export default class VueOrders extends Vue {
  public namedCommodities(name: string): CommodityFormat {
    if (name === "DELIVERY") {
      return {
        name: this.$t("orders.commodities.delivery") as string,
        icon: require("@/orders/assets/icons/orders/icon-pedidos-entrega.svg") as string,
      };
    } else if (name === "DELIVERY_CHANGE") {
      return {
        name: this.$t("orders.commodities.deliveryChange") as string as string,
        icon: require("@/orders/assets/icons/orders/icon-pedidos-entrega-de-cambio.svg") as string,
      };
    } else if (name === "PICKUP") {
      return {
        name: this.$t("orders.commodities.pickup") as string,
        icon: require("@/orders/assets/icons/orders/icon-pedidos-recogida.svg") as string,
      };
    } else if (name === "SUPPLY") {
      return {
        name: this.$t("orders.commodities.supply") as string,
        icon: require("@/orders/assets/icons/orders/icon-pedidos-material-operativo.svg") as string,
      };
    } else if (name === "ASSISTANCE") {
      return {
        name: this.$t("orders.commodities.other") as string,
        icon: require("@/orders/assets/icons/orders/icon-pedidos-asistencia-tecnica.svg") as string,
      };
    }
    return {} as CommodityFormat;
  }

  public namedStatus(code: string, order?: Order): StatusFormat {
    if (code === "PEN") {
      return {
        name: this.$t("orders.statuses.pen") as string,
        icon: require("@/orders/assets/icons/icon-calendar.svg") as string,
        description: this.$t("orders.statuses.penDescription") as string,
        date: order?.creationDate || "",
      };
    } else if (code === "CON") {
      return {
        name: this.$t("orders.statuses.con") as string,
        icon: require("@/orders/assets/icons/icon-check-circle.svg") as string,
        description: this.$t("orders.statuses.conDescription") as string,
        date: order?.citConfirmDate || "",
      };
    } else if (code === "DEP") {
      return {
        name: this.$t("orders.statuses.dep") as string,
        icon: require("@/orders/assets/icons/icon-pedidos.svg") as string,
        description: this.$t("orders.statuses.depDescription") as string,
        date: order?.citDepartDate || "",
      };
    } else if (code === "OTW") {
      return {
        name: this.$t("orders.statuses.otw") as string,
        icon: require("@/orders/assets/icons/icon-icon-icn-stores.svg") as string,
        description: this.$t("orders.statuses.otwDescription") as string,
        date: order?.citDate || "",
      };
    } else {
      return {
        name: this.$t("orders.statuses.other") as string,
        icon: require("@/orders/assets/icons/icon-pedidos.svg") as string,
        description: this.$t("orders.statuses.otherDescription") as string,
        date: order?.citExecutedDate || "",
      };
    }
  }
}
