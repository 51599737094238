import { OrderServiceMock } from "@/orders";

export const mockOrderService = (): OrderServiceMock => ({
  fetchOrders: jest.fn(),
  fetchOrderCurrencies: jest.fn(),
  fetchSupplies: jest.fn(),
  fetchOrderCreateAssistance: jest.fn(),
  fetchOrderCreateSupply: jest.fn(),
  fetchOrderCreatePickup: jest.fn(),
  fetchOrderCreateDeliveryChange: jest.fn(),
  fetchOrderDenominations: jest.fn(),
  fetchOrderDenominationPacks: jest.fn(),
  fetchFrequencies: jest.fn(),
  fetchCancellationReasons: jest.fn(),
  patchOrderCancellation: jest.fn(),
  patchOrderAssistance: jest.fn(),
});
