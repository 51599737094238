import { PackAmounts } from "@/orders/entities/packAmounts";
import { Currency, RawDenominationPack } from "@/orders/entities";

export class DenominationPack {
  readonly id: string;
  readonly code: string;
  readonly description: string;
  readonly commercialDescription: string;
  readonly total: number;
  readonly active: boolean;
  readonly currency: Currency;
  readonly packAmounts: PackAmounts[];

  constructor(data: RawDenominationPack) {
    this.id = data.id;
    this.code = data.code;
    this.description = data.description;
    this.commercialDescription = data.commercialDescription;
    this.total = data.total;
    this.active = data.active;
    this.currency = new Currency(data.currency);
    this.packAmounts = data.packAmounts.map((value) => new PackAmounts(value));
  }
}
