import { i18n } from "@/i18n";
import { convert, LocalDateTime, ZonedDateTime, Instant, ZoneId } from "@js-joda/core";
import { isToday, isYesterday } from "date-fns";
import format from "date-fns/format";
import "@js-joda/timezone";
import { de, enUS, es, pt } from "date-fns/locale";
import { Component, Vue } from "vue-property-decorator";

declare module "vue/types/vue" {
  interface Vue {
    formatDate(dateTime: Date | LocalDateTime, pattern?: string): string | undefined;
    formatDateString(date: string): string;
    formatDateTime(dateTime: Date | LocalDateTime, pattern?: string): string | undefined;
    formatDateTimeTodayYerterday(dateTime: Date | LocalDateTime): string | undefined;
    formatTime(dateTime: Date | LocalDateTime, includeSeconds?: boolean, pattern?: string): string | undefined;
    toDate(dateTime: Date | LocalDateTime): Date;
    getTodayDate(): Date;
    getTodayDateTime(): number;
    getYesterdayDate(): Date;
    getThisWeekDates(): { start: Date; end: Date };
    getThisMonthDates(): { start: Date; end: Date };
    isIntervalValid(start: Date | null, end: Date | null): boolean;
    addDaysToDate(date: Date, days: number): Date;
    intervalExceedNumberOfDays(start: Date, end: Date, days: number): boolean;
    compareDates(date1: Date | LocalDateTime | undefined, date2: Date | LocalDateTime | undefined): number;
    subtractYears(date: Date, years: number): Date;
    subtractMonths(date: Date, months: number): Date;
    convertDateStringYYYYMMDDToDate(date: string): Date;
    formatISODateToCurrentZone(date: string): string;
  }
}

@Component({
  name: "datetime-mixin",
})
export default class DateTimeMixin extends Vue {
  getLocale(): Locale {
    let locale = es;
    switch (i18n.locale) {
      case "en":
        locale = enUS;
        break;
      case "pt":
        locale = pt;
        break;
      case "de":
        locale = de;
        break;
    }
    return locale;
  }

  public formatDateString(date: string): string {
    return this.filterDate(date.replace("[GMT]", "")) + " " + this.filterTime(date.replace("[GMT]", ""));
  }

  /** https://date-fns.org/v2.28.0/docs/format */
  public formatDateTime(dateTime: Date | LocalDateTime, pattern?: string): string | undefined {
    const locale = this.getLocale();
    pattern = pattern ? pattern : "P pp";
    try {
      return dateTime ? format(this.toDate(dateTime), pattern, { locale }) : "";
    } catch (error) {
      console.error(error);
    }
  }

  public formatDate(dateTime: Date | LocalDateTime, pattern?: string): string | undefined {
    pattern = pattern ? pattern : "P";
    return this.formatDateTime(dateTime, pattern);
  }

  public formatTime(dateTime: Date | LocalDateTime, includeSeconds?: boolean, pattern?: string): string | undefined {
    if (!pattern) pattern = includeSeconds ? "pp" : "p";
    return this.formatDateTime(dateTime, pattern);
  }

  formatDateTimeTodayYerterday(dateTime: Date | LocalDateTime): string | undefined {
    if (isToday(this.toDate(dateTime))) {
      const text = this.$t("date.todayAt");
      return `${text} ${this.formatDateTime(dateTime, "p")}`;
    }

    if (isYesterday(this.toDate(dateTime))) {
      const text = this.$t("date.yesterdayAt");
      return `${text} ${this.formatDateTime(dateTime, "p")}`;
    }
    const text = this.$t("date.at");
    return ` ${this.formatDateTime(dateTime, "P")} ${text} ${this.formatDateTime(dateTime, "p")}`;
  }

  toDate(dateTime: Date | LocalDateTime): Date {
    if (dateTime instanceof LocalDateTime) {
      return convert(dateTime as LocalDateTime).toDate();
    }
    return dateTime;
  }

  milliSecondOfOneDay = 86400000;

  getTodayDate(): Date {
    return new Date();
  }

  getTodayDateTime(): number {
    return this.getTodayDate().getTime();
  }

  getYesterdayDate(): Date {
    return new Date(this.getTodayDateTime() - this.milliSecondOfOneDay);
  }

  getThisWeekDates(): { start: Date; end: Date } {
    const today = this.getTodayDateTime();
    const todayDayOfTheWeek = this.getTodayDate().getDay();
    let start = new Date(today - 6 * this.milliSecondOfOneDay);
    let end = new Date(today);
    if (todayDayOfTheWeek != 0) {
      start = new Date(today - (todayDayOfTheWeek - 1) * this.milliSecondOfOneDay);
      end = new Date(today + (7 - todayDayOfTheWeek) * this.milliSecondOfOneDay);
    }
    return { start: start, end: end };
  }

  getThisMonthDates(): { start: Date; end: Date } {
    const thisYear = this.getTodayDate().getFullYear();
    const thisMonth = this.getTodayDate().getMonth();
    const start = new Date(thisYear, thisMonth, 1);
    const dayOfTheMonthNumber = new Date(thisYear, thisMonth + 1, 0).getDate();
    const end = new Date(thisYear, thisMonth, dayOfTheMonthNumber);
    return { start: start, end: end };
  }

  isIntervalValid(start: Date | null, end: Date | null): boolean {
    if (start == null || end == null) return true;
    return start.getTime() < end.getTime();
  }

  addDaysToDate(date: Date, days: number): Date {
    return new Date(date.getTime() + days * this.milliSecondOfOneDay);
  }

  intervalExceedNumberOfDays(start: Date, end: Date, days: number): boolean {
    return end.getTime() - start.getTime() > days * this.milliSecondOfOneDay;
  }

  public compareDates(date1: Date | LocalDateTime | undefined, date2: Date | LocalDateTime | undefined): number {
    const equals = 0;
    const firstMajor = 1;
    const secondMajor = -1;

    if (date1 == undefined && date2 == undefined) return equals;
    if (date2 == undefined) return firstMajor;
    if (date1 == undefined) return secondMajor;

    const datetime1 = this.toDate(date1).getTime();
    const datetime2 = this.toDate(date2).getTime();

    if (datetime1 > datetime2) return firstMajor;
    if (datetime1 < datetime2) return secondMajor;
    return equals;
  }

  subtractYears(date: Date, years: number): Date {
    return new Date(date.setFullYear(date.getFullYear() - years));
  }

  subtractMonths(date: Date, months: number): Date {
    return new Date(date.setMonth(date.getMonth() - months));
  }

  convertDateStringYYYYMMDDToDate(dateString: string): Date {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  formatISODateToCurrentZone(dateTime: string): string {
    if (!dateTime) return dateTime;

    const startZonedIdIndex: number = dateTime.indexOf("[");
    const formatDateTimeWithoutZoneId: string =
      startZonedIdIndex !== -1 ? dateTime.substring(0, startZonedIdIndex) : dateTime;

    const dateTimeToUTC: string = new Date(formatDateTimeWithoutZoneId).toISOString();

    const actualTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return ZonedDateTime.ofInstant(Instant.parse(dateTimeToUTC), ZoneId.of(actualTimeZone)).toString();
  }
}
