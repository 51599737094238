
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/orders/components/Icon/index.vue";
import Utils from "@/orders/components/Commons/utils";
import { Getter } from "vuex-class";
import VueOrders from "@/orders/vue-orders";
import {
  Commodity,
  OrderDTO,
  Order,
  UpdateOrderAssistance,
  CreateOrderPickupData,
  CreateOrderDeliveryData,
} from "@/orders";
import DateTimeMixin from "@/mixins/datetime";

@Component({
  components: {
    Icon,
  },
  mixins: [VueOrders, DateTimeMixin],
})
export default class StepFive extends Vue {
  private readonly pattern = `EEEE, d MMMM`;
  // Props
  @Prop({ required: true }) active!: boolean;
  @Prop({ required: true }) dateSelected!: Date;
  @Prop({ required: true }) baseOrder!: OrderDTO;
  @Prop() sendOrders!: OrderDTO[];
  @Prop() order: Order | undefined;

  // Data
  @Getter("getCommoditiesService") commodities!: Commodity[];
  stepPosition = 5;
  plus: string = require("@/orders/assets/icons/icon-icon-icn-plus.svg");
  edit: string = require("@/orders/assets/icons/icon-icn-edit-3.svg");
  remove: string = require("@/orders/assets/icons/icon-x.svg");
  selectedPoint: { id: number | null } = { id: null };

  // Methods
  editStep(order: OrderDTO): void {
    this.$emit("editStep", order);
  }

  removeOrder(order: OrderDTO): void {
    this.$emit("removeOrder", order);
  }

  submitOrders(): void {
    this.$emit("submitOrders", this.sendOrders);
  }

  async updateOrder(order: any, prepare: Record<string, any>) {
    if (order.commoditySelected === "ASSISTANCE") {
      const toUpdate: UpdateOrderAssistance = {
        orderId: prepare.id,
        comments: prepare.comments,
        assistanceFailureId: prepare.assistanceFailureId,
        serviceDate: prepare.serviceDate,
        requestInitialTime: prepare.requestInitialTime,
        requestFinalTime: prepare.requestFinalTime,
      };
      await this.$services.order.patchOrderAssistance(toUpdate);
    }
  }

  currencyFormat(amount: number): string {
    return Utils.currencyFormat(amount);
  }

  formatDate(fecha: Date): string {
    const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "short", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("es-ES", options);
    return formatter.format(fecha);
  }

  closeModal(): void {
    this.$emit("closeModal");
  }

  prevPage(): void {
    this.$emit("prevPage", true);
  }

  selectCommodity(item: { id: number | null }): void {
    this.selectedPoint = item;
  }

  showAmount(item: OrderDTO): string {
    if (item.commodity.name === "PICKUP") {
      const data = item as OrderDTO;
      const obj = data.createOrder as CreateOrderPickupData;
      const total = obj!.requestedCurrency.reduce((acc, curr) => acc + Number(curr.quantity), 0);
      return this.currencyFormat(total) + " $";
    } else if (item.commodity.name === "DELIVERY_CHANGE" || item.commodity.name === "DELIVERY") {
      const data = item as OrderDTO;
      const obj = data.createOrder as CreateOrderDeliveryData;
      const totalPrice = obj.requestedDenominations.reduce((acc, curr) => acc + curr.total, 0);
      return totalPrice ? this.currencyFormat(totalPrice) + " $" : "";
    } else {
      return "- $";
    }
  }

  showCommodities(commodities: Commodity[], orders: OrderDTO[]): Commodity[] {
    if (orders[0].commodity.name === "ASSISTANCE") {
      return [];
    }
    return commodities.filter((commodity) => {
      return !orders.some((order) => order.commodity.name === commodity.name || commodity.name === "ASSISTANCE");
    });
  }

  addOrder(commodity: Commodity): void {
    this.$emit("addOrder", commodity);
  }

  get formattedDateSelected(): string {
    return this.formatDateTime(this.dateSelected, this.pattern)!;
  }
}
