import { CreateRequestedCurrency, RequestedCurrencyData } from "@/orders/entities/requestedCurrency";
import {
  Commodity,
  Country,
  CreateOrderData,
  CreateRequestDenomination,
  CreateRequestedDenominationPack,
  CreateRequestedSupply,
  OrderProgrammingHourType,
  RequestedDenominationPack,
  RequestedDenominations,
  RequestedSupply,
  ServicePoint,
} from "@/orders/entities";

interface CreateOrder {
  comments: string;
  serviceDate: string;
  requestInitialTime: string;
  requestFinalTime: string;
  servicePointId: string;
}

export interface OrderDTO {
  commodity: Commodity;
  servicePoint: ServicePoint;
  country: Country;
  createOrder?: CreateOrderData;
}

//Order-Data
export interface OrderDeliveryData {
  requestedDenominations: RequestedDenominations[];
  requestedDenominationPack: RequestedDenominationPack[];
  totalPrice: number;
}

export interface OrderAssistanceData {
  assistanceFailuresId: string;
}

export interface OrderPickupData {
  requestedCurrency: RequestedCurrencyData[];
  undeclaredAmount: boolean;
}

export interface OrderSupplyData {
  requestedSupplies: RequestedSupply[];
}

//Create-Data
export interface CreateOrderAssistanceData extends CreateOrder {
  assistanceFailuresId: string;
}

export interface CreateOrderPickupData extends CreateOrder {
  hasPickupDeclared: boolean;
  requestedCurrency: RequestedCurrencyData[];
}

export interface CreateOrderDeliveryData extends CreateOrder {
  requestedDenominations: RequestedDenominations[];
  requestedDenominationPack: RequestedDenominationPack[];
}

export interface CreateOrderSupplyData extends CreateOrder {
  requestedSupplies: RequestedSupply[];
}

//Create-Service
export interface CreateOrderPickupService extends CreateOrder {
  hasPickupDeclared?: boolean;
  requestedCurrency: CreateRequestedCurrency[];
}

export interface CreateOrderDeliveryService extends CreateOrder {
  requestedDenominations: CreateRequestDenomination[];
  requestedDenominationPack: CreateRequestedDenominationPack[];
}

export interface CreateOrderAssistanceService extends CreateOrder {
  assistanceFailuresId: string;
}

export interface CreateOrderSupplyService extends CreateOrder {
  requestedSupplies: CreateRequestedSupply[];
}

export class OrderDateDTO {
  serviceDate: Date;
  requestInitialTime: Date;
  requestFinalTime: Date;
  typeDate: OrderProgrammingHourType;

  constructor(serviceDate: Date, requestInitialTime: Date, requestFinalTime: Date, typeDate: OrderProgrammingHourType) {
    this.serviceDate = serviceDate;
    this.requestInitialTime = requestInitialTime;
    this.requestFinalTime = requestFinalTime;
    this.typeDate = typeDate;
  }

  private formatDate(date: Date): string {
    return date.toISOString();
  }

  get serviceDateString(): string {
    return this.formatDate(this.serviceDate);
  }

  get requestInitialTimeString(): string {
    return this.formatDate(this.requestInitialTime);
  }

  get requestFinalTimeString(): string {
    return this.formatDate(this.requestFinalTime);
  }
}

export class FactoryCreateOrder {
  static buildCreateOrderDeliveryService(
    delivery: CreateOrderDeliveryData,
    servicePointId: string
  ): CreateOrderDeliveryService {
    const requestedDenominationPack = delivery.requestedDenominationPack.map((denominationPack) => {
      return {
        denominationPackId: denominationPack.denominationPack.id,
        quantity: denominationPack.quantity,
      };
    });
    const requestedDenominations = delivery.requestedDenominations.map((denomination) => {
      return {
        denominationId: denomination.denomination.id,
        quantity: denomination.quantity,
      };
    });
    return {
      comments: delivery.comments,
      serviceDate: delivery.serviceDate,
      requestInitialTime: delivery.requestInitialTime,
      requestFinalTime: delivery.requestFinalTime,
      servicePointId: servicePointId,
      requestedDenominationPack: requestedDenominationPack,
      requestedDenominations: requestedDenominations,
    };
  }

  static buildCreateOrderPickupService(
    pickup: CreateOrderPickupData,
    servicePointId: string
  ): CreateOrderPickupService {
    const requestedCurrencies = pickup.requestedCurrency.map((currency) => {
      return {
        currencyId: currency.currency.id,
        total: currency.quantity,
      };
    });
    return {
      comments: pickup.comments,
      serviceDate: pickup.serviceDate,
      requestInitialTime: pickup.requestInitialTime,
      requestFinalTime: pickup.requestFinalTime,
      servicePointId: servicePointId,
      hasPickupDeclared: pickup.hasPickupDeclared,
      requestedCurrency: requestedCurrencies,
    };
  }

  static buildCreateOrderAssistanceService(
    assistance: CreateOrderAssistanceData,
    servicePointId: string
  ): CreateOrderAssistanceService {
    return {
      comments: assistance.comments,
      serviceDate: assistance.serviceDate,
      requestInitialTime: assistance.requestInitialTime,
      requestFinalTime: assistance.requestFinalTime,
      servicePointId: servicePointId,
      assistanceFailuresId: assistance.assistanceFailuresId,
    };
  }

  static buildCreateOrderSupplyService(
    supply: CreateOrderSupplyData,
    servicePointId: string
  ): CreateOrderSupplyService {
    const requestedSupplies = supply.requestedSupplies.map((supply) => {
      return {
        supplyId: supply.supply.id,
        quantity: supply.quantity,
      };
    });
    return {
      comments: supply.comments,
      serviceDate: supply.serviceDate,
      requestInitialTime: supply.requestInitialTime,
      requestFinalTime: supply.requestFinalTime,
      servicePointId: servicePointId,
      requestedSupplies: requestedSupplies,
    };
  }
}
