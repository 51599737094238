
import { Component, Prop, Vue } from "vue-property-decorator";
import { Order } from "@/orders";
import VueOrders from "@/orders/vue-orders";
@Component({
  components: {},
  mixins: [VueOrders],
})
export class CardDetail extends Vue {
  @Prop({ required: true }) order!: Order;

  openModalTripulation(): void {
    this.$emit("openTripulation", this.order);
  }

  openModalDetail(): void {
    this.$emit("openModalDetail", this.order);
  }

  dateFromString(dateString: string): Date {
    const index = dateString.indexOf("[");
    if (index >= 0) return new Date(dateString.slice(0, index));
    return new Date(dateString);
  }

  dateFormated(fecha: string): string {
    const date = this.dateFromString(fecha);
    const month = date.toLocaleDateString("es-ES", { month: "long" });
    const day = date.toLocaleDateString("es-ES", { weekday: "long" });
    return day + ", " + date.getDate() + " " + month;
  }
}
export default CardDetail;
